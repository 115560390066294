import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./milestone.css";
import { FaTrash } from "react-icons/fa";
import Sidebar from "../sidebar";
import Header from "../Header/index";
import uri from "../../config";
import { IoChevronBackOutline } from "react-icons/io5";
import Select from "react-select";

import { Modal, Button, Form, ModalHeader, Tabs, Tab } from "react-bootstrap";
const Milestone = () => {
  const [projects, setProjects] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [members, setMembers] = useState([]);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [editselectedTask, setEditSelectedTask] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showMilestoneModal, setShowMilestoneModal] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const organization = localStorage.getItem("organization");
  const project = localStorage.getItem("selectedProjectName");
  const user = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [activeTab, setActiveTab] = useState("ongoing");
  const [isEditing, setIsEditing] = useState(false);
  const [newMilestone, setNewMilestone] = useState({
    organization: organization,
    projectName: project || "", // Use the value from localStorage (project)
    title: "",
    createdBy: user || "", // Use the value from localStorage (user)
    description: "",
    startDate: "",
    endDate: "",
    tasks: [],
    goals: [{ title: "", assigneeName: "", status: "Not Started" }],
  });
  const isAdmin = role === "Admin" || role === "SuperAdmin";

  const [isConcluded, setIsConcluded] = useState(false);

  const [showGoals, setShowGoals] = useState(false);

  useEffect(() => {
    if (selectedMilestone) {
      setIsConcluded(selectedMilestone.concluded || false);
    }
  }, [selectedMilestone]);

  useEffect(() => {
    fetchProjects();
    if (selectedProjectName) {
      fetchMilestones();
      fetchMembers();
      fetchTasks();
    }
  }, [selectedProjectName]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(""); // Clear the error message after 3 seconds
      }, 3000);

      // Cleanup the timer if the component unmounts or errorMessage changes
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const fetchProjects = async () => {
    try {
      const organizationId = localStorage.getItem("organization");
      const email = localStorage.getItem("email");

      const response = await axios.get(`${uri}/api/projects/timesheet`, {
        params: { organizationId, email },
      });

      setProjects(response.data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchMilestones = async () => {
    try {
      const response = await axios.get(`${uri}/api/milestones`, {
        params: { projectName: selectedProjectName },
      });
      const fetchedMilestones = response.data;
      setMilestones(fetchedMilestones);

      // Optionally set the first milestone as the selected one
      if (fetchedMilestones.length > 0) {
        setSelectedMilestone(fetchedMilestones[0]);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
    }
  };


  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${uri}/api/addtask/${selectedProjectName}`
      );
      const tasksData = response.data
        .filter((task) => task.taskName)
        .map((task) => task.taskName);
      setTasks(tasksData);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await axios.get(`${uri}/api/usersByProject`, {
        params: { projectName: selectedProjectName },
      });
      setMembers(
        response.data.map((member) => `${member.firstName} ${member.lastName}`)
      );
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const handleMilestoneClick = (milestone) => {
    setSelectedMilestone(milestone);
    setShowMilestoneModal(true);
  };

  const handleProjectClick = (projectName) => {
    localStorage.setItem("selectedProjectName", projectName);
    setSelectedProjectName(projectName);
  };
  const handleCloseMilestoneModal = () => {
    setShowMilestoneModal(false);
    setIsEditing(false);
    setSelectedMilestone(null);
  };
  const handleBackClick = () => {
    localStorage.removeItem("selectedProjectName");
    setSelectedProjectName("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMilestone({ ...newMilestone, [name]: value });
  };

  const handleTaskSelect = (e) => {
    const selectedTask = e.target.value;
    setSelectedTask(selectedTask);
    if (!newMilestone.tasks.includes(selectedTask)) {
      setNewMilestone((prev) => ({
        ...prev,
        tasks: [...prev.tasks, selectedTask],
      }));
    }
  };

  const handleEditTaskSelect = (e) => {
    const editselectedTask = e.target.value;
    setEditSelectedTask(editselectedTask);
    if (!newMilestone.tasks.includes(editselectedTask)) {
      setNewMilestone((prev) => ({
        ...prev,
        tasks: [...prev.tasks, editselectedTask],
      }));
    }
  };

  const handleGoalChange = (index, field, value) => {
    const updatedGoals = [...newMilestone.goals];
    updatedGoals[index][field] = value;
    setNewMilestone({ ...newMilestone, goals: updatedGoals });
  };

  const addGoal = () => {
    setNewMilestone({
      ...newMilestone,
      goals: [
        ...newMilestone.goals,
        { title: "", assigneeName: "", status: "Not Started" },
      ],
    });
  };

  const deleteGoal = (index) => {
    const updatedGoals = newMilestone.goals.filter((goal, i) => i !== index);
    setNewMilestone({ ...newMilestone, goals: updatedGoals });
  };

  const deletepreviousGoal = (index) => {
    if (selectedMilestone) {
      const updatedGoals = selectedMilestone.goals.filter(
        (goal, i) => i !== index
      );
      setSelectedMilestone({ ...selectedMilestone, goals: updatedGoals });
    }
  };

  const addnewGoal = () => {
    const newGoal = { title: "", assigneeName: "", status: "Not Started" };

    // Add new goal to the selectedMilestone's goals array
    setSelectedMilestone((prevState) => ({
      ...prevState,
      goals: [...prevState.goals, newGoal],
    }));
  };

  // Handle changes for goal properties

  const createMilestone = async (e) => {
    e.preventDefault();
    try {
      // Ensure that the projectName and createdBy are present in newMilestone
      console.log(newMilestone); // Check if these values are set correctly
      await axios.post(`${uri}/api/create-milestone`, newMilestone);
      fetchMilestones();
      setShowModal(false);
      setNewMilestone({
        organization: organization,
        projectName: project,
        title: "",
        createdBy: user,
        description: "",
        startDate: "",
        endDate: "",
        tasks: [],
        goals: [{ title: "", assigneeName: "", status: "Not Started" }],
      });
      setErrorMessage(""); // Clear error message if creation is successful
    } catch (error) {
      // Display error message if it exists in response
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("An error occurred while creating the milestone.");
      }
      console.error("Error creating milestone:", error);
    }
  };

  const saveChanges = async () => {
    try {
      // Ensure startDate and endDate are converted to Date objects
      const updatedMilestone = {
        ...selectedMilestone,
        goals: selectedMilestone.goals,
        tasks: selectedMilestone.tasks,
        description: selectedMilestone.description,
        title: selectedMilestone.title,
        startDate: new Date(selectedMilestone.startDate),  // Convert to Date object
        endDate: new Date(selectedMilestone.endDate),      // Convert to Date object
      };

      console.log("Updated Milestone:", updatedMilestone); // Log to check data

      await axios.put(
        `${uri}/api/create-milestone/update/${selectedMilestone.milestoneId}`,
        updatedMilestone
      );

      setIsEditing(false);
      fetchMilestones();
      setShowMilestoneModal(false);
    } catch (error) {
      console.error("Error updating milestone:", error);
    }
  };


  const concludeMilestone = async () => {
    try {
      const response = await axios.put(
        `${uri}/api/create-milestone/conclude/${selectedMilestone._id}`
      );
      setSelectedMilestone({
        ...selectedMilestone,
        concluded: true,
        concludedAt: response.data.milestone.concludedAt,
      });
      setIsConcluded(true);
      await fetchMilestones();
    } catch (error) {
      console.error("Error concluding milestone:", error);
    }
  };

  const calculateMilestoneProgress = (goals) => {
    const completedGoals = goals.filter(
      (goal) => goal.status === "Completed"
    ).length;
    const totalGoals = goals.length;
    return totalGoals > 0 ? (completedGoals / totalGoals) * 100 : 0;
  };

  const getStatusColor = (percentage) => {
    if (percentage > 90) return "green";
    if (percentage >= 70) return "orange";
    return "red";
  };

  const countGoalsByStatus = (goals, status) => {
    return goals.filter((goal) => goal.status === status).length;
  };

  const resetForm = () => {
    setNewMilestone({
      organization: organization,
      projectName: project || "",
      title: "",
      createdBy: user || "",
      description: "",
      startDate: "",
      endDate: "",
      tasks: [], // Ensure tasks is reset to an empty array
      goals: [{ title: "", assigneeName: "", status: "Not Started" }],
    });
    setSelectedTask(""); // Clear selectedTask for dropdown reset
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setSelectedMilestone({
      ...selectedMilestone,
      startDate: newStartDate,
    });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setSelectedMilestone({
      ...selectedMilestone,
      endDate: newEndDate,
    });
  };

  const renderMilestoneTable = (filteredMilestones) => (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Milestone ID</th>
          <th>Name</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Total Goals</th>
          <th>Not Started</th>
          <th>In Progress</th>
          <th>Completed</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {filteredMilestones.length > 0 ? (
          filteredMilestones.map((milestone) => {
            const progress = calculateMilestoneProgress(milestone.goals);
            const color = getStatusColor(progress);
            const totalGoals = milestone.goals.length;
            const notStartedCount = countGoalsByStatus(milestone.goals, "Not Started");
            const inProgressCount = countGoalsByStatus(milestone.goals, "In Progress");
            const completedCount = countGoalsByStatus(milestone.goals, "Completed");

            return (
              <tr key={milestone._id}>
                <td>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleMilestoneClick(milestone)}
                  >
                    {milestone.milestoneId}
                  </span>
                </td>
                <td>{milestone.title}</td>
                <td>{new Date(milestone.startDate).toLocaleDateString()}</td>
                <td>{new Date(milestone.endDate).toLocaleDateString()}</td>
                <td>{totalGoals}</td>
                <td>{notStartedCount}</td>
                <td>{inProgressCount}</td>
                <td>{completedCount}</td>
                <td>
                  <div
                    style={{
                      width: "100%",
                      height: "10px",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      style={{
                        width: `${progress}%`,
                        backgroundColor: color,
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>
                  <span>{progress.toFixed(0)}%</span>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="9">No milestones to display.</td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <div>
      <Header />
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <Sidebar
          isExpanded={isSidebarExpanded}
          setIsExpanded={setIsSidebarExpanded}
        />
        <div className="container" style={{ marginLeft: "15vw" }}>
          {!selectedProjectName && (
            <div className="project-table">
              <h2>Projects</h2>
              <table style={{ width: "80vw" }}>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Client</th>
                    <th>Engagement Type</th>
                    <th>Currency</th>
                    <th>Region</th>
                    <th>Billable</th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project, index) => (
                    <tr key={index}>
                      <td
                        onClick={() => handleProjectClick(project.projectName)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {project.projectName}
                      </td>
                      <td>{project.clientName}</td>
                      <td>{project.engagementType}</td>
                      <td>{project.currencyType}</td>
                      <td>{project.regionType}</td>
                      <td>{project.billable ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Milestone Form */}
          {selectedProjectName && (

            <Button
              onClick={handleBackClick}
              className="btn btn-btn-primary"
              style={{ marginTop: "5vw" }}

            >
              Back
            </Button>

          )}
          <br />
          {selectedProjectName && milestones.length === 0 && (
            <div className="no-milestone-message">
              <p>
                No milestone is found for the selected project. Start by
                creating one.
              </p>
              {(role === "SuperAdmin" || role === "Admin") && (
                <Button
                  className="new-milestone-btn"
                  onClick={() => setShowModal(true)}
                >
                  New Milestone
                </Button>
              )}
            </div>
          )}

          {milestones.length > 0 && selectedProjectName && (
            <div className="milestone-table">
              <h2>Milestones</h2>
              {(role === "SuperAdmin" || role === "Admin") && (
                <Button
                  className="new-milestone-btn mb-3"
                  onClick={() => setShowModal(true)}
                >
                  New Milestone
                </Button>
              )}
              <Tabs
                activeKey={activeTab}
                onSelect={(tabKey) => setActiveTab(tabKey)}
                className="mb-3"
              >
                <Tab eventKey="ongoing" title="Ongoing Milestones">
                  {renderMilestoneTable(milestones.filter((m) => !m.concluded))}
                </Tab>
                <Tab eventKey="completed" title="Completed Milestones">
                  {renderMilestoneTable(milestones.filter((m) => m.concluded))}
                </Tab>
              </Tabs>
            </div>
          )}
          <Modal
            show={showModal}
            onHide={() => {
              resetForm();
              setShowModal(false);
            }}
            centered
            id="custom-modal-backdrop"
            size="xl"
          >
            <Modal.Header closeButton></Modal.Header>
            <div id="custom-modal-content">
              <h2>{selectedProjectName}: New Milestone</h2>
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <Form onSubmit={createMilestone}>
                {/* Title Input */}
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Milestone Title"
                    value={newMilestone.title}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Description Textarea */}
                <Form.Group className="mt-2">
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="Description"
                    value={newMilestone.description}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>

                {/* Start Date */}
                <Form.Group className="mt-2">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={newMilestone.startDate}
                    onChange={handleInputChange}
                    max={newMilestone.endDate || undefined} // Restrict the max date to the selected end date
                    required
                  />
                </Form.Group>

                {/* End Date */}
                <Form.Group className="mt-2">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={newMilestone.endDate}
                    onChange={handleInputChange}
                    min={newMilestone.startDate || undefined} // Restrict the min date to the selected start date
                    required
                  />
                </Form.Group>

                {/* Tasks */}
                <Form.Group>
                  <Form.Label>Tasks</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleTaskSelect}
                    value={selectedTask || ""}
                    required
                  >
                    <option value="" disabled>
                      {selectedTask ? selectedTask : "Select a task"}
                    </option>
                    {tasks.map((task, index) => (
                      <option key={index} value={task}>
                        {task}
                      </option>
                    ))}
                  </Form.Control>
                  <div className="mt-2">
                    {newMilestone.tasks.map((task, index) => (
                      <span key={index} className="badge badge-secondary mr-1">
                        {task}
                      </span>
                    ))}
                  </div>
                </Form.Group>

                {/* Goals Section */}
                <h5 className="mt-4">Goals</h5>
                {newMilestone.goals.map((goal, index) => (
                  <div key={index} className="d-flex align-items-center mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Goal Title"
                      value={goal.title}
                      onChange={(e) =>
                        handleGoalChange(index, "title", e.target.value)
                      }
                      required
                      style={{ width: "25vw", marginRight: "0.5vw" }}
                    />
                    <Form.Control
                      as="select"
                      value={goal.assigneeName}
                      onChange={(e) =>
                        handleGoalChange(index, "assigneeName", e.target.value)
                      }
                      required
                      style={{ width: "25vw", marginRight: "0.5vw" }}
                    >
                      <option value="" disabled>
                        Select Assignee
                      </option>
                      {members.map((member, idx) => (
                        <option key={idx} value={member}>
                          {member}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control
                      as="select"
                      value={goal.status}
                      onChange={(e) =>
                        handleGoalChange(index, "status", e.target.value)
                      }
                      style={{ width: "25vw" }}
                      required
                    >
                      <option value="Not Started">Not Started</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                    </Form.Control>
                    {/* Render the delete button only if there is more than one goal */}
                    {newMilestone.goals.length > 1 && (
                      <FaTrash
                        className="text-danger ml-3"
                        onClick={() => deleteGoal(index)}
                        style={{ cursor: "pointer", marginLeft: "1vw" }}
                      />
                    )}
                  </div>
                ))}

                <Button
                  variant="outline-primary"
                  onClick={addGoal}
                  className="new-milestone-btn"
                >
                  Add Goal
                </Button>

                {/* Submit Button */}
                <Button variant="primary" type="submit" className="mt-3">
                  Create Milestone
                </Button>
              </Form>
            </div>
          </Modal>

          <Modal
            show={showMilestoneModal}
            onHide={handleCloseMilestoneModal}
            centered
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedMilestone?.milestoneId}: {selectedMilestone?.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isAdmin && !isConcluded && (
                <Button
                  variant="success"
                  onClick={concludeMilestone}
                  style={{ marginBottom: "1rem" }}
                >
                  Complete Milestone
                </Button>
              )}

              {/* Editable Title */}
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  as="textarea"
                  value={selectedMilestone?.title}
                  onChange={(e) =>
                    setSelectedMilestone({
                      ...selectedMilestone,
                      title: e.target.value,
                    })
                  }
                  onFocus={() => setIsEditing(true)}
                  readOnly={!isAdmin || isConcluded} // Disable if not admin or concluded
                />
              </Form.Group>

              {/* Editable Description */}
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  value={selectedMilestone?.description}
                  onChange={(e) =>
                    setSelectedMilestone({
                      ...selectedMilestone,
                      description: e.target.value,
                    })
                  }
                  onFocus={() => setIsEditing(true)}
                  readOnly={!isAdmin || isConcluded} // Disable if not admin or concluded
                />
              </Form.Group>

              {/* Editable Dates */}
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedMilestone?.startDate ? selectedMilestone.startDate.split("T")[0] : ""}
                  onChange={handleStartDateChange}
                  onFocus={() => setIsEditing(true)}
                  readOnly={!isAdmin || isConcluded || new Date(selectedMilestone?.startDate) < new Date()}
                  max={selectedMilestone?.endDate ? selectedMilestone.endDate.split("T")[0] : ""}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedMilestone?.endDate ? selectedMilestone.endDate.split("T")[0] : ""}
                  onChange={handleEndDateChange}
                  onFocus={() => setIsEditing(true)}
                  readOnly={!isAdmin || isConcluded}
                  min={selectedMilestone?.startDate ? selectedMilestone.startDate.split("T")[0] : ""}
                />
              </Form.Group>

              {/* Editable Tasks */}
              <Form.Group>
                <Form.Label>Tasks</Form.Label>
                <Select
                  isMulti
                  options={tasks.map((task) => ({ label: task, value: task }))}
                  value={selectedMilestone?.tasks?.map((task) => ({
                    label: task,
                    value: task,
                  }))}
                  onChange={(selectedOptions) => {
                    const updatedTasks = selectedOptions.map((option) => option.value);
                    setSelectedMilestone((prevMilestone) => ({
                      ...prevMilestone,
                      tasks: updatedTasks,
                    }));
                    setIsEditing(true);
                  }}
                  isDisabled={!isAdmin || isConcluded} // Disable if not admin or concluded
                  required
                />
              </Form.Group>

              {/* Editable Goals */}
              <h4>Goals:</h4>
              {selectedMilestone?.goals?.map((goal, index) => (
                <div key={index} className="d-flex align-items-center mb-3">
                  {/* Goal Title */}
                  <Form.Control
                    type="text"
                    value={goal.title}
                    onChange={(e) => {
                      if (selectedMilestone) {
                        const updatedGoals = [...selectedMilestone.goals];
                        updatedGoals[index].title = e.target.value;
                        setSelectedMilestone({
                          ...selectedMilestone,
                          goals: updatedGoals,
                        });
                      }
                    }}
                    onFocus={() => setIsEditing(true)}
                    className="ml-3"
                    style={{ width: "25vw", marginRight: "0.5vw" }}
                    readOnly={!isAdmin || isConcluded} // Disable if not admin or concluded
                  />

                  {/* Assignee Name */}
                  <Form.Control
                    as="select"
                    value={goal.assigneeName}
                    onChange={(e) => {
                      if (selectedMilestone) {
                        const updatedGoals = [...selectedMilestone.goals];
                        updatedGoals[index].assigneeName = e.target.value;
                        setSelectedMilestone({
                          ...selectedMilestone,
                          goals: updatedGoals,
                        });
                      }
                    }}
                    onFocus={() => setIsEditing(true)}
                    className="ml-3"
                    style={{ width: "25vw", marginRight: "0.5vw" }}
                    disabled={!isAdmin || isConcluded} // Disable if not admin or concluded
                  >
                    {members.map((member, idx) => (
                      <option key={idx} value={member}>
                        {member}
                      </option>
                    ))}
                  </Form.Control>

                  {/* Goal Status - Always Editable */}
                  <Form.Control
                    as="select"
                    value={goal.status}
                    onChange={(e) => {
                      if (selectedMilestone) {
                        const updatedGoals = [...selectedMilestone.goals];
                        updatedGoals[index].status = e.target.value;
                        setSelectedMilestone({
                          ...selectedMilestone,
                          goals: updatedGoals,
                        });
                      }
                    }}
                    onFocus={() => setIsEditing(true)}
                    className="ml-3"
                    style={{ width: "25vw" }}
                    disabled={isConcluded}
                  >
                    <option value="Not Started">Not Started</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                  </Form.Control>

                  {/* Delete Goal - Only if Admin */}
                  {isAdmin && isEditing && !isConcluded && (
                    <FaTrash
                      className="text-danger ml-3"
                      onClick={() => deletepreviousGoal(index)}
                      style={{ cursor: "pointer", marginLeft: "1vw" }}
                    />
                  )}
                </div>
              ))}

              {/* New Goals */}
              {showGoals && (
                <div>
                  {newMilestone.goals.map((goal, index) => (
                    <div key={index} className="d-flex align-items-center mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Goal Title"
                        value={goal.title}
                        onChange={(e) =>
                          handleGoalChange(index, "title", e.target.value)
                        }
                        required
                        style={{ width: "25vw", marginRight: "0.5vw" }}
                        readOnly={isConcluded} // Disable editing for concluded milestones
                      />
                      <Form.Control
                        as="select"
                        value={goal.assigneeName}
                        onChange={(e) =>
                          handleGoalChange(index, "assigneeName", e.target.value)
                        }
                        style={{ width: "25vw", marginRight: "0.5vw" }}
                        disabled={isConcluded} // Disable if concluded
                      >
                        <option value="" disabled>
                          Select Assignee
                        </option>
                        {members.map((member, idx) => (
                          <option key={idx} value={member}>
                            {member}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control
                        as="select"
                        value={goal.status}
                        onChange={(e) =>
                          handleGoalChange(index, "status", e.target.value)
                        }
                        style={{ width: "25vw" }}
                      >
                        <option value="Not Started">Not Started</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                      </Form.Control>
                      {newMilestone.goals.length > 1 && (
                        <FaTrash
                          className="text-danger ml-3"
                          onClick={() => deleteGoal(index)}
                          style={{ cursor: "pointer", marginLeft: "1vw" }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isEditing && !isConcluded && (
                <Button variant="primary" onClick={saveChanges}>
                  Save Changes
                </Button>
              )}
              {isEditing && isAdmin && !isConcluded && (
                <Button variant="outline-primary" onClick={addnewGoal}>
                  Add Goal
                </Button>
              )}
              <Button variant="secondary" onClick={handleCloseMilestoneModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
      </div>
    </div>
  );
};
export default Milestone;
