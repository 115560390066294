import React, { useEffect, useState } from 'react';
import axios from 'axios';
import uri from '../../config';
import Header from "../Header/index";
import Sidebar from "../sidebar";
import { Button, Table, Spinner, Alert, Dropdown, Form } from 'react-bootstrap';

const AdminDashboard = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [usernames, setUsernames] = useState([]);
  const [statuses, setStatuses] = useState(['Pending', 'Approved', 'Rejected']);
  const [usernameFilter, setUsernameFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [usernameSearch, setUsernameSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(`${uri}/api/adminreq`);
        const filteredRequests = response.data.filter(request => request.approverName === userName);
        setRequests(filteredRequests);
        setFilteredRequests(filteredRequests);
        setUsernames([...new Set(filteredRequests.map(request => request.username))]);
      } catch (error) {
        console.error('Error fetching requests', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [userName]);

  useEffect(() => {
    const applyFilters = () => {
      setFilteredRequests(requests.filter(request =>
        (usernameFilter.length === 0 || usernameFilter.includes(request.username)) &&
        (statusFilter.length === 0 || statusFilter.includes(request.approvalStatus))
      ));
    };

    applyFilters();
  }, [usernameFilter, statusFilter, requests]);

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'username') {
      setUsernameFilter(prev => prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]);
    } else if (filterType === 'status') {
      setStatusFilter(prev => prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]);
    }
  };

  const updateRequestStatus = async (id, status) => {
    try {
      const response = await axios.patch(`${uri}/api/adminreq/${id}`, { approvalStatus: status });
      setRequests(requests.map(request => request._id === id ? response.data : request));
    } catch (error) {
      console.error(`Error updating request status to ${status}`, error);
    }
  };

  if (loading) {
    return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
  }

  return (
    <div className="row2">
      <div className="container" style={{ boxShadow: 'none' }}>
        {/* Filter dropdowns */}
        <div className="filter-container" style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <Dropdown autoClose="outside" >
            <Dropdown.Toggle variant="outline-secondary" style={{ width: '10vw' }}>
              User Name
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '150%' }}>
              <Form.Control
                type="text"
                placeholder="Search username"
                value={usernameSearch}
                onChange={(e) => setUsernameSearch(e.target.value)}
                style={{ marginBottom: '0.5rem' }}
              />
              {usernames
                .filter(username => username.toLowerCase().includes(usernameSearch.toLowerCase()))
                .map(username => (
                  <Dropdown.Item
                    key={username}
                    onClick={() => handleFilterChange('username', username)}
                    as="label"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Form.Check
                      type="checkbox"
                      label={username}
                      checked={usernameFilter.includes(username)}
                      onChange={() => handleFilterChange('username', username)}
                      style={{ pointerEvents: 'none' }}
                    />
                  </Dropdown.Item>
                ))}
              {usernames.filter(username => username.toLowerCase().includes(usernameSearch.toLowerCase())).length === 0 && (
                <Dropdown.Item disabled>No results found</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown autoClose="outside" >
            <Dropdown.Toggle variant="outline-secondary" style={{ width: '10vw' }}>
              Filter by Status
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              <Form.Control
                type="text"
                placeholder="Search status"
                value={statusSearch}
                onChange={(e) => setStatusSearch(e.target.value)}
                style={{ marginBottom: '0.5rem' }}
              />
              {statuses
                .filter(status => status.toLowerCase().includes(statusSearch.toLowerCase()))
                .map(status => (
                  <Dropdown.Item
                    key={status}
                    onClick={() => handleFilterChange('status', status)}
                    as="label"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Form.Check
                      type="checkbox"
                      label={status}
                      checked={statusFilter.includes(status)}
                      onChange={() => handleFilterChange('status', status)}
                      style={{ pointerEvents: 'none' }}
                    />
                  </Dropdown.Item>
                ))}
              {statuses.filter(status => status.toLowerCase().includes(statusSearch.toLowerCase())).length === 0 && (
                <Dropdown.Item disabled>No results found</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Requests Table */}
        {filteredRequests.length === 0 ? (
          <Alert variant="info">No requests matching your filters.</Alert>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Description</th>
                <th>Username</th>
                <th>Approver Name</th>
                <th>Start</th>
                <th>End</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredRequests.map(request => (
                <tr key={request._id}>
                  <td>{request.description}</td>
                  <td>{request.username}</td>
                  <td>{request.approverName}</td>
                  <td>{new Date(request.start).toLocaleDateString('en-GB')}</td>
                  <td>{new Date(request.end).toLocaleDateString('en-GB')}</td>
                  <td>{request.approvalStatus}</td>
                  <td>
                    {request.approvalStatus === 'Pending' && (
                      <>
                        <Button variant="success" onClick={() => updateRequestStatus(request._id, 'Approved')}>Approve</Button>{' '}
                        <Button variant="danger" onClick={() => updateRequestStatus(request._id, 'Rejected')}>Reject</Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
