import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import Sidebar from "../sidebar";
import Header from "../Header";
import { ToastContainer } from "react-toastify";
import AdminDashboard from "../AdminDashboard";
import ApproverWSR from "../approverwsr";

const MySpacePage = () => {
    // State for the active tab
    const [activeTab, setActiveTab] = useState("timesheet-unlock");

    // Callback to reset the active tab
    const resetToDefaultTab = () => {
        setActiveTab("timesheet-unlock");
    };

    return (
        <div>
            <Header />
            <ToastContainer />
            <div style={{ display: "flex", width: "auto", height: "100vh" }}>
                {/* Pass the reset callback to Sidebar */}
                <Sidebar onMySpaceClick={resetToDefaultTab} />
                <div className="container mt-5" style={{ marginLeft: "17vw", marginTop: "15vw", boxShadow: "none" }}>
                    <h2>My Space</h2>
                    <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                        <Nav variant="tabs">
                            <Nav.Item>
                                <Nav.Link eventKey="timesheet-unlock">Timesheet Unlock Requests</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="wsr-requests">WSR Requests</Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-3">
                            {/* Timesheet Unlock Requests Tab */}
                            <Tab.Pane eventKey="timesheet-unlock">
                                <AdminDashboard />
                            </Tab.Pane>

                            {/* WSR Requests Tab */}
                            <Tab.Pane eventKey="wsr-requests">
                                <ApproverWSR />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
};

export default MySpacePage;
