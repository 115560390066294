import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css'; // Assuming you have a CSS file for styling

function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-404">404</h1>
        <h2>Nothing to see here</h2>
        <p>
          Page you are trying to open does not exist. You may have mistyped the address, 
          or the page has been moved to another URL. If you think this is an error contact support.
        </p>
        <button onClick={goHome} className="home-button">
          Take me back to home page
        </button>
      </div>
    </div>
  );
}

export default NotFound;
