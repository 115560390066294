import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Container, Table, Row, Col, Form, Button, Toast, Dropdown, DropdownButton, OverlayTrigger, FormControl, Tooltip, FormCheck } from 'react-bootstrap';
import Sidebar from "../sidebar";
import Header from "../Header/index";
import axios from 'axios';
import uri from '../../config';
import moment from 'moment';
import SummaryReportChart from '../summarychart.js';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css'; // Import the Toastify CSS for proper styling
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsListColumnsReverse } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { MdOutlineFilterAltOff } from "react-icons/md";
import style from "./report.css"
import spurtrack from './spurtrack.png'
import * as XLSX from 'xlsx';
import Select from 'react-select';
import WeeklyStatusReport from '../WSR/index.jsx';

const ReportsPage = () => {
    const [key, setKey] = useState('summary');
    const [summaryData, setSummaryData] = useState([]);
    const [detailedData, setDetailedData] = useState([]);
    const [filters, setFilters] = useState({
        team: [],        // Initialize as empty array
        client: [],      // Initialize as empty array
        project: [],     // Initialize as empty array
        task: [],        // Initialize as empty array
        status: '',      // Can also be an array for multi-select if needed
        noDescription: false,
        dateRange: '',
    });

    const [appliedFilters, setAppliedFilters] = useState(null); // To show applied filters
    const [showToast, setShowToast] = useState(false);
    const [searchTermTeam, setSearchTermTeam] = useState('');
    const [searchTermClient, setSearchTermClient] = useState('');
    const [searchTermProject, setSearchTermProject] = useState('');
    const [searchTermTask, setSearchTermTask] = useState('');
    const [teams, setTeams] = useState([]);
    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [focusedInput, setFocusedInput] = useState(null); // For custom date range
    const role = localStorage.getItem("role");
    const userName = localStorage.getItem("userName");
    const [visibleColumns, setVisibleColumns] = useState({
        date: true,
        duration: true,
        task: true,
        project: true,
        User: true,
        description: true,
        startTime: false,
        endTime: false,
        measurements: false,
        additionalFields: false,
    });

    const [searchTerms, setSearchTerms] = useState({
        team: '',
        client: '',
        project: '',
        task: ''
    }); // For handling search input inside dropdowns
    const organization = localStorage.getItem('organization');
    // Fetch options for the filters
    useEffect(() => {
        const organization = localStorage.getItem('organization');

        axios.get(`${uri}/api/reports/filters`, {
            headers: {
                organization: organization
            }
        })
            .then(response => {
                setTeams(response.data.teams);
                setClients(response.data.clients);
                setProjects(response.data.projects);
                setTasks(response.data.tasks);
            })
            .catch(error => console.error('Error fetching filter options:', error));
    }, []);

    useEffect(() => {
        const memberTeamFilter = teams.some(team => team.name === userName) ? [userName] : [];
        const newFilters = (prevFilters => ({ ...prevFilters, team: memberTeamFilter }));
        
        // Update filters
        setFilters(newFilters);
    
        // Fetch reports only when the filter is properly updated
        if (memberTeamFilter.length > 0) {
            fetchSummaryReport({ team: memberTeamFilter });
            fetchDetailedReport({ team: memberTeamFilter });
        }
    }, [role, userName, teams]);

    const fetchSummaryReport = (filterParams = {}) => {
        const organization = localStorage.getItem('organization');  // Fetch organization from local storage

        axios.get(`${uri}/api/reports/summary`, {
            params: filterParams,
            headers: { 'organization': organization }  // Add organization in headers
        })
            .then(response => setSummaryData(response.data))
            .catch(error => console.error('Error fetching summary data:', error));
    };

    const filterOptions = (options, searchTerm) =>
        options.filter(option => option.toLowerCase().includes(searchTerm.toLowerCase()));

    const fetchDetailedReport = (filterParams = {}) => {
        const organization = localStorage.getItem('organization');  // Fetch organization from local storage

        axios.get(`${uri}/api/reports/detailed`, {
            params: filterParams,
            headers: { 'organization': organization }  // Add organization in headers
        })
            .then(response => setDetailedData(response.data))
            .catch(error => console.error('Error fetching detailed data:', error));
    };

    const handleDateRangeChange = (range) => {
        let startDate = null;
        let endDate = null;

        switch (range) {
            case 'Today':
                startDate = moment().startOf('day').toDate();
                endDate = moment().endOf('day').toDate();
                break;
            case 'Yesterday':
                startDate = moment().subtract(1, 'days').startOf('day').toDate();
                endDate = moment().subtract(1, 'days').endOf('day').toDate();
                break;
            case 'ThisWeek':
                startDate = moment().startOf('week').toDate();
                endDate = moment().endOf('week').toDate();
                break;
            case 'LastWeek':
                startDate = moment().subtract(1, 'week').startOf('week').toDate();
                endDate = moment().subtract(1, 'week').endOf('week').toDate();
                break;
            case 'ThisMonth':
                startDate = moment().startOf('month').toDate();
                endDate = moment().endOf('month').toDate();
                break;
            case 'LastMonth':
                startDate = moment().subtract(1, 'month').startOf('month').toDate();
                endDate = moment().subtract(1, 'month').endOf('month').toDate();
                break;
            default:
                break;
        }

        // Update the date range state
        setDateRange({ startDate, endDate });
        const updatedFilters = { ...filters, dateRange: range, startDate, endDate };
        setFilters(updatedFilters);
        applyFilters(updatedFilters);
    };


    // Handle custom date range change
    const handleCustomDateRangeChange = ({ startDate, endDate }) => {
        setDateRange({ startDate, endDate });
        setFilters({ ...filters, startDate, endDate });
        applyFilters({ ...filters, startDate, endDate });
    };



    // Handle filter change
    const handleFilterChange = (name, value) => {
        setFilters(prevFilters => {
            if (name === 'status') {
                if (value === 'All') {
                    // For "All", clear the billable filter
                    const updatedFilters = { ...prevFilters, [name]: [] }; // Clear the status filter
                    applyFilters(updatedFilters);
                    return updatedFilters;
                } else {
                    // For Billable or Non-Billable, update the filter accordingly
                    const updatedFilters = { ...prevFilters, [name]: [value] }; // Set Billable or Non-Billable
                    applyFilters(updatedFilters);
                    return updatedFilters;
                }
            }
            // Check if the filter is boolean (like 'noDescription')
            if (typeof prevFilters[name] === 'boolean') {
                // If it's a boolean, simply update it with the new value
                const updatedFilters = { ...prevFilters, [name]: value };
                applyFilters(updatedFilters);
                return updatedFilters;
            }

            // Ensure the filter is initialized as an array if it's supposed to be one
            const isArray = Array.isArray(prevFilters[name]);
            const isSelected = isArray ? prevFilters[name].includes(value) : false;

            // Handle specific filters like 'client'
            if (name === 'client') {
                if (!isSelected) {
                    // Client is being selected, so add it to the selected clients
                    const newSelectedClients = isArray ? [...prevFilters.client, value] : [value];

                    // Fetch projects associated with all selected clients
                    const associatedProjects = projects
                        .filter(project => newSelectedClients.includes(project.clientName))
                        .map(project => project.projectName);

                    // Fetch tasks associated with the projects of all selected clients
                    const associatedTasks = tasks
                        .filter(task => associatedProjects.includes(task.projectName))
                        .map(task => task.taskName);

                    // Fetch teams working on the projects of all selected clients
                    const associatedTeams = teams
                        .filter(team =>
                            team.selectedProjects.some(project =>
                                associatedProjects.includes(project)
                            )
                        )
                        .map(team => team.name);

                    // Update filters for client, project, tasks, and teams
                    const updatedFilters = {
                        ...prevFilters,
                        client: newSelectedClients,
                        project: associatedProjects,
                        task: associatedTasks,
                        team: associatedTeams,
                    };

                    applyFilters(updatedFilters);
                    return updatedFilters;
                } else {
                    // If client is deselected, remove its associations
                    const remainingClients = prevFilters.client.filter(client => client !== value);

                    // Fetch projects associated with the remaining selected clients
                    const associatedProjects = projects
                        .filter(project => remainingClients.includes(project.clientName))
                        .map(project => project.projectName);

                    // Fetch tasks associated with the projects of the remaining clients
                    const associatedTasks = tasks
                        .filter(task => associatedProjects.includes(task.projectName))
                        .map(task => task.taskName);

                    // Fetch teams working on the projects of the remaining clients
                    const associatedTeams = teams
                        .filter(team =>
                            team.selectedProjects.some(project =>
                                associatedProjects.includes(project)
                            )
                        )
                        .map(team => team.name);

                    // Update filters for client, project, tasks, and teams
                    const updatedFilters = {
                        ...prevFilters,
                        client: remainingClients,
                        project: associatedProjects,
                        task: associatedTasks,
                        team: associatedTeams,
                    };

                    applyFilters(updatedFilters);
                    return updatedFilters;
                }
            }

            // Handle specific filters like 'project'
            if (name === 'project') {
                if (!isSelected) {
                    // Fetch the client associated with the selected project
                    const selectedProject = projects.find(project => project.projectName === value);
                    const associatedClient = selectedProject?.clientName || '';

                    // Fetch tasks associated with the selected project
                    const associatedTasks = tasks
                        .filter(task => task.projectName === value)
                        .map(task => task.taskName);

                    // Fetch teams working on the selected project
                    const associatedTeams = teams
                        .filter(team => team.selectedProjects.includes(value))
                        .map(team => team.name);

                    // Update filters for project, client, tasks, and teams
                    const updatedFilters = {
                        ...prevFilters,
                        project: [value], // Only one project can be selected
                        client: associatedClient
                            ? Array.from(new Set([...prevFilters.client, associatedClient])) // Add to selected clients if not already present
                            : prevFilters.client,
                        task: associatedTasks,
                        team: associatedTeams,
                    };

                    applyFilters(updatedFilters);
                    return updatedFilters;
                } else {
                    // If project is deselected, remove its associations
                    const tasksToRemove = tasks
                        .filter(task => task.projectName === value)
                        .map(task => task.taskName);

                    const teamsToRemove = teams
                        .filter(team => team.selectedProjects.includes(value))
                        .map(team => team.name);

                    const updatedFilters = {
                        ...prevFilters,
                        project: prevFilters.project.filter(project => project !== value),
                        task: prevFilters.task.filter(task => !tasksToRemove.includes(task)),
                        team: prevFilters.team.filter(team => !teamsToRemove.includes(team)),
                    };

                    applyFilters(updatedFilters);
                    return updatedFilters;
                }
            }

            // Default behavior for other filters (generic case)
            const updatedFilter = isSelected
                ? prevFilters[name].filter(item => item !== value) // Remove if already selected
                : [...(isArray ? prevFilters[name] : []), value];  // Add if not selected or if it's not initialized

            // Update the filters with the generic update
            const updatedFilters = { ...prevFilters, [name]: updatedFilter };

            applyFilters(updatedFilters);
            return updatedFilters;
        });
    };




    const handleSearchChange = (event) => {
        const { name, value } = event.target;
        setSearchTerms({ ...searchTerms, [name]: value });
    };


    const clearFilters = () => {
        setFilters({
            team: [],        // Initialize as empty array
            client: [],      // Initialize as empty array
            project: [],     // Initialize as empty array
            task: [],        // Initialize as empty array
            status: '',      // Can also be an array for multi-select if needed
            noDescription: false,
            dateRange: '',
        });

        setSearchTerms({
            team: '',
            client: '',
            project: '',
            task: ''
        });

        fetchSummaryReport();
        fetchDetailedReport();
        setDateRange({ startDate: null, endDate: null });
        setAppliedFilters(null);

        setShowToast(true);

        setTimeout(() => setShowToast(false), 3000);
    };



    const applyFilters = (activeFilters) => {
        // Handle date range if set to 'custom'
        if (activeFilters.dateRange === 'custom' && dateRange.startDate && dateRange.endDate) {
            activeFilters.startDate = dateRange.startDate.toISOString();
            activeFilters.endDate = dateRange.endDate.toISOString();
        }

        // Call your API functions to fetch reports with the active filters
        fetchSummaryReport(activeFilters);
        fetchDetailedReport(activeFilters);

        // Save the applied filters for reference
        setAppliedFilters(activeFilters);

        // Show a toast notification for feedback
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };




    const toggleColumnVisibility = (column) => {
        setVisibleColumns({ ...visibleColumns, [column]: !visibleColumns[column] });
    };

    const totalMinutes = detailedData.reduce((acc, item) => {
        if (item.duration) { // Ensure duration exists
            const [hours, minutes] = item.duration.split(':').map(Number); // Split "HH:MM" into numbers
            acc += hours * 60 + minutes; // Convert to minutes and add to the accumulator
        }
        return acc;
    }, 0);

    // Convert total minutes back to hours and minutes
    const totalHours = Math.floor(totalMinutes / 60); // Total hours
    const totalRemainingMinutes = totalMinutes % 60; // Remaining minutes




    const exportToPDF = (key) => {
        const user = localStorage.getItem("userName") || "User";
        const organization = localStorage.getItem('organization') || 'Your Organization';

        // Determine which tab content to export based on the provided key
        const input = document.querySelector(key === 'summary' ? '#summary-tab-content' : '#detailed-tab-content');

        if (!input) {
            console.error('The selected tab content is not found in the DOM.');
            return;
        }

        html2canvas(input, { scale: 2 })  // Scale up the canvas for better quality
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');  // PDF setup: portrait mode, A4 size
                const imgWidth = 190;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const pageHeight = pdf.internal.pageSize.height;
                const pageWidth = pdf.internal.pageSize.width;
                let position = 30;  // Content starting position below the header
                let currentPage = 1;

                // Calculate total pages needed for the content
                const totalPages = Math.ceil(imgHeight / pageHeight);

                // Add header with organization name and logo on the first page
                const logoImage = spurtrack;  // Ensure spurtrack contains your logo image data
                const logoWidth = 30;
                const logoHeight = 15;  // Adjust the logo height while maintaining aspect ratio

                pdf.addImage(logoImage, 'PNG', 10, 10, logoWidth, logoHeight);  // Adjust logo position as needed
                pdf.setFontSize(12);
                pdf.text(organization, pageWidth / 2, 20, { align: 'center' });

                // Loop through pages and add content
                while (position < imgHeight) {
                    // If this is not the first page, add a new page
                    if (currentPage > 1) {
                        pdf.addPage();
                        position = 0;  // Reset position for new page
                    }

                    // Add the image/content
                    const remainingHeight = imgHeight - position;
                    const heightToPrint = Math.min(remainingHeight, pageHeight - 40); // 40mm for header/footer space

                    pdf.addImage(imgData, 'PNG', 10, position + 30, imgWidth, heightToPrint);  // Adjust y position

                    // Add footer on each page
                    pdf.setFontSize(10);
                    pdf.text(`Created by Spurtrack`, 10, pageHeight - 10);
                    pdf.text(`Page ${currentPage} of ${totalPages}`, pageWidth - 20, pageHeight - 10);

                    // Update position for the next page
                    position += pageHeight;
                    currentPage++;
                }

                // Save the PDF with a dynamic filename
                pdf.save(`${key}-report.pdf`);

                // Notify user after successful generation
                sendNotificationToUser({
                    userName: user,
                    message: 'Your report has been generated successfully!',
                    organization
                });
            })
            .catch((error) => console.error('Error generating PDF:', error));
    };


    const exportToExcel = () => {
        const user = localStorage.getItem("userName") || "User";
        const organization = localStorage.getItem('organization') || 'Your Organization';

        // Collect the data from the table
        const tableElement = document.querySelector('#detailed-tab-content table');

        if (!tableElement) {
            console.error('Table not found in the DOM.');
            return;
        }

        // Extract data from the table into an array
        const data = [];
        const headers = [];

        // Get the header row
        const headerCells = tableElement.querySelectorAll('thead tr th');
        headerCells.forEach((headerCell) => {
            headers.push(headerCell.innerText.trim());
        });
        data.push(headers); // Add headers to the data array

        // Get the table body rows
        const bodyRows = tableElement.querySelectorAll('tbody tr');
        bodyRows.forEach((row) => {
            const rowData = [];
            const cells = row.querySelectorAll('td');
            cells.forEach((cell) => {
                rowData.push(cell.innerText.trim());
            });
            data.push(rowData); // Add row data to the data array
        });

        // Convert the data to an Excel file
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Detailed Report");

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `Excel-detailed-report.xlsx`);

        // Send notification to the user after generating the Excel file
        sendNotificationToUser({
            userName: user,
            message: 'Your Excel report has been generated successfully!',
            organization
        });
    };

    // The sendNotificationToUser function


    // Function to send the notification via the API
    const sendNotificationToUser = (notificationData) => {
        fetch(`${uri}/api/notifications`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notificationData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                } else {
                    console.error('Failed to send notification:', data.message);
                }
            })
            .catch(error => {
                console.error('Error sending notification:', error);
            });
    };




    return (
        <div>
            <Header />
            <div style={{ display: "flex", width: "100%", height: "100vh" }}>
                <Sidebar />

                <div style={{ marginLeft: "16vw", marginTop: "10vh", width: "75vw" }}>

                    <>
                        <div style={{ display: 'flex', gap: '10px', alignItems: 'flex-end', marginBottom: '20px' }}>
                            {role !== 'Member' && (
                                <>
                                    <div style={{ flexGrow: 1 }}>
                                        {/* Client Dropdown */}
                                        <Form.Group controlId="client">
                                            <Dropdown autoClose="outside"> {/* Add autoClose="outside" here */}
                                                <Dropdown.Toggle variant="outline-secondary">
                                                    Client
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search Client"
                                                        name="client"
                                                        value={searchTerms.client}
                                                        onChange={handleSearchChange}
                                                        className="mb-2"
                                                    />
                                                    <Dropdown.Divider />
                                                    {clients.length > 0 ? (
                                                        clients
                                                            .filter(client => client.toLowerCase().includes(searchTerms.client.toLowerCase()))
                                                            .map((client, index) => (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => handleFilterChange('client', client)}
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label={client}
                                                                            checked={filters.client.includes(client)}
                                                                            onChange={() => handleFilterChange('client', client)}
                                                                            onClick={e => e.stopPropagation()}
                                                                        />
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ))
                                                    ) : (
                                                        <Dropdown.Item disabled>No Clients Available</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>

                                    <div>
                                        {/* Project Dropdown */}
                                        <Form.Group controlId="project">
                                            <Dropdown autoClose="outside"> {/* Add autoClose="outside" here */}
                                                <Dropdown.Toggle variant="outline-secondary">
                                                    Project
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Project"
                                                        name="project"
                                                        value={searchTerms.project}
                                                        onChange={handleSearchChange}
                                                        className="mb-2"
                                                    />
                                                    <Dropdown.Divider />
                                                    {projects.length > 0 ? (
                                                        projects
                                                            .filter(project => project.projectName.toLowerCase().includes(searchTerms.project.toLowerCase()))
                                                            .map((project, index) => (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => handleFilterChange('project', project.projectName)}
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label={project.projectName}
                                                                            checked={filters.project.includes(project.projectName)}
                                                                            onChange={() => handleFilterChange('project', project.projectName)}
                                                                            onClick={e => e.stopPropagation()}
                                                                        />
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ))
                                                    ) : (
                                                        <Dropdown.Item disabled>No Projects Available</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        {/* Team Dropdown */}
                                        <Form.Group controlId="team">
                                            <Dropdown autoClose="outside">
                                                <Dropdown.Toggle variant="outline-secondary">Team</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search Team"
                                                        name="team"
                                                        value={searchTerms.team}
                                                        onChange={handleSearchChange}
                                                        className="mb-2"
                                                    />
                                                    <Dropdown.Divider />
                                                    {teams.length > 0 ? (
                                                        teams
                                                            .filter(team => team?.name?.toLowerCase().includes(searchTerms.team.toLowerCase()))
                                                            .map((team, index) => (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => handleFilterChange('team', team.name)}
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label={team.name}
                                                                            checked={
                                                                                role === "Member"
                                                                                    ? team.name === userName
                                                                                    : filters.team.includes(team.name)
                                                                            }
                                                                            onChange={() => handleFilterChange('team', team.name)}
                                                                            onClick={e => e.stopPropagation()}
                                                                            disabled={role === "Member" && team.name !== userName}
                                                                        />
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ))
                                                    ) : (
                                                        <Dropdown.Item disabled>No Teams Available</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        {/* Task Dropdown */}
                                        <Form.Group controlId="task">

                                            <Dropdown autoClose="outside"> {/* Add autoClose="outside" here */}
                                                <Dropdown.Toggle variant="outline-secondary">
                                                    Task
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search Task"
                                                        name="task"
                                                        value={searchTerms.task}
                                                        onChange={handleSearchChange}
                                                        className="mb-2"
                                                    />
                                                    <Dropdown.Divider />
                                                    {tasks.length > 0 ? (
                                                        tasks
                                                            .filter(task => task.taskName.toLowerCase().includes(searchTerms.task.toLowerCase()))
                                                            .map((task, index) => (
                                                                <Dropdown.Item
                                                                    key={index}
                                                                    onClick={() => handleFilterChange('task', task.taskName)}
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            label={task.taskName}
                                                                            checked={filters.task.includes(task.taskName)}
                                                                            onChange={() => handleFilterChange('task', task.taskName)}
                                                                            onClick={e => e.stopPropagation()}
                                                                        />
                                                                    </div>
                                                                </Dropdown.Item>
                                                            ))
                                                    ) : (
                                                        <Dropdown.Item disabled>No Tasks Available</Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </div>




                                    <div>
                                        <Form.Group controlId="status">
                                            {/* Conditionally render the DropdownButton based on the value of key */}
                                            {key !== 'wsr' && (
                                                <DropdownButton title={"Status"} variant="outline-secondary">
                                                    <Dropdown.Item onClick={() => handleFilterChange('status', 'All')}>All</Dropdown.Item> {/* New "All" Option */}
                                                    <Dropdown.Item onClick={() => handleFilterChange('status', 'Billable')}>Billable</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleFilterChange('status', 'Non-Billable')}>Non-Billable</Dropdown.Item>
                                                </DropdownButton>
                                            )}
                                        </Form.Group>
                                    </div>
                                </>
                            )}
                            <div style={{ flex: '210' }}>
                                <Form.Group controlId="dateRange">

                                    <DropdownButton title={filters.dateRange || "Date Range"} variant="outline-secondary">
                                        <Dropdown.Item onClick={() => handleDateRangeChange('Today')}>Today</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDateRangeChange('Yesterday')}>Yesterday</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDateRangeChange('ThisWeek')}>This Week</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDateRangeChange('LastWeek')}>Last Week</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDateRangeChange('ThisMonth')}>This Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDateRangeChange('LastMonth')}>Last Month</Dropdown.Item>
                                    </DropdownButton>
                                </Form.Group>
                            </div>
                            
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Form.Group controlId="customDateRangeStart">
                                    <DatePicker
                                        selected={dateRange.startDate}
                                        onChange={(date) => {
                                            if (date > dateRange.endDate) {
                                                // If the selected start date is greater than the end date, reset the end date
                                                handleCustomDateRangeChange({ startDate: date, endDate: null }); // or set it to a valid date if needed
                                            } else {
                                                handleCustomDateRangeChange({ startDate: date, endDate: dateRange.endDate });
                                            }
                                        }}
                                        selectsStart
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="Start Date"
                                        // Disable the start date picker if the end date is set and is earlier than the start date
                                        disabled={dateRange.endDate && dateRange.startDate > dateRange.endDate}
                                    />
                                </Form.Group>
                                <Form.Group controlId="customDateRangeEnd">
                                    <DatePicker
                                        selected={dateRange.endDate}
                                        onChange={(date) => {
                                            // If the selected end date is less than the current start date
                                            if (date < dateRange.startDate) {
                                                return; // Prevent the change if the end date is less than the start date
                                            }
                                            handleCustomDateRangeChange({ startDate: dateRange.startDate, endDate: date });
                                        }}
                                        selectsEnd
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                        minDate={dateRange.startDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        placeholderText="End Date"
                                    />
                                </Form.Group>
                            </div>
                            <div style={{ flex: '1 0 auto' }}>
                                <Button
                                    variant="primary"
                                    className="mt-3 clear"
                                    onClick={() => {
                                        if (role === 'Member') {
                                            // Clear both custom and predefined date ranges
                                            handleCustomDateRangeChange({ startDate: null, endDate: null });
                                            setFilters(prevFilters => ({ ...prevFilters, dateRange: null }));

                                            // Add the team filter logic here
                                            const memberTeamFilter = teams.some(team => team.name === userName) ? [userName] : [];

                                            // Update the filters with the team filter
                                            setFilters(prevFilters => ({ ...prevFilters, team: memberTeamFilter }));

                                            // Call the fetch functions with the team filter
                                            fetchSummaryReport({ team: memberTeamFilter });
                                            fetchDetailedReport({ team: memberTeamFilter });
                                        } else {
                                            // Clear other filters, and only the custom date range
                                            clearFilters();
                                        }
                                    }}
                                    disabled={
                                        role === 'Member'
                                            ? !dateRange.startDate && !dateRange.endDate && !filters.dateRange
                                            : !filters.client.length &&
                                            !filters.project.length &&
                                            !filters.team.length &&
                                            !filters.task.length &&
                                            !filters.status &&
                                            !dateRange.startDate &&
                                            !dateRange.endDate
                                    }
                                >
                                    <MdOutlineFilterAltOff /> Clear Filters
                                </Button>
                            </div>



                        </div>

                        <Row className="mb-4">
                            <Col>
                                <Form.Group controlId="noDescription">
                                    <Form.Check
                                        type="checkbox"
                                        label="No Description"
                                        name="noDescription"
                                        checked={filters.noDescription}
                                        onChange={(e) => handleFilterChange('noDescription', e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>

                    <Tabs
                        id="report-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        {/* Summary Report Tab */}
                        <Tab eventKey="summary" title="Summary">
                            <div id="summary-tab-content">
                                <h3>Summary Report</h3>
                                {summaryData.length > 0 ? (
                                    <SummaryReportChart summaryData={summaryData} />
                                ) : (
                                    <p className="text-center">No data available for the selected filters.</p>
                                )}
                            </div>
                        </Tab>

                        {/* Detailed Report Tab */}
                        <Tab eventKey="detailed" title="Detailed">

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <Dropdown style={{ marginRight: '1rem' }}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                        <BsListColumnsReverse />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ width: '15vw' }}>
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-date"
                                            label="Date"
                                            checked={visibleColumns.date}
                                            onChange={() => toggleColumnVisibility('date')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-user"
                                            label="User"
                                            checked={visibleColumns.User}
                                            onChange={() => toggleColumnVisibility('User')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-duration"
                                            label="Duration"
                                            checked={visibleColumns.duration}
                                            onChange={() => toggleColumnVisibility('duration')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-task"
                                            label="Task"
                                            checked={visibleColumns.task}
                                            onChange={() => toggleColumnVisibility('task')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-project"
                                            label="Project"
                                            checked={visibleColumns.project}
                                            onChange={() => toggleColumnVisibility('project')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-description"
                                            label="Description"
                                            checked={visibleColumns.description}
                                            onChange={() => toggleColumnVisibility('description')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-startTime"
                                            label="Start Time"
                                            checked={visibleColumns.startTime}
                                            onChange={() => toggleColumnVisibility('startTime')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-endTime"
                                            label="End Time"
                                            checked={visibleColumns.endTime}
                                            onChange={() => toggleColumnVisibility('endTime')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-measurements"
                                            label="Measurements"
                                            checked={visibleColumns.measurements}
                                            onChange={() => toggleColumnVisibility('measurements')}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            id="checkbox-additionalFields"
                                            label="Additional Fields"
                                            checked={visibleColumns.additionalFields}
                                            onChange={() => toggleColumnVisibility('additionalFields')}
                                        />
                                    </Dropdown.Menu>

                                </Dropdown>

                                <Button style={{ marginRight: '1rem' }} onClick={exportToPDF}>Export as PDF</Button>
                                <Button onClick={exportToExcel}>Export as Excel</Button>
                            </div>

                            <h3>Detailed Report</h3>
                            <div id="detailed-tab-content">
                                <h4>Total Duration: {totalHours}H {totalRemainingMinutes}M</h4>

                                <Table hover>
                                    <thead>
                                        <tr>
                                            {visibleColumns.date && (
                                                <th style={{ width: visibleColumns.measurements || visibleColumns.additionalFields ? '6vw' : '8vw' }}>Date</th>
                                            )}
                                            {visibleColumns.project && (
                                                <th style={{ width: visibleColumns.measurements || visibleColumns.additionalFields ? '6vw' : '8vw' }}>Project</th>
                                            )}
                                            {visibleColumns.task && (
                                                <th style={{ width: visibleColumns.measurements || visibleColumns.additionalFields ? '10vw' : '15vw' }}>Task</th>
                                            )}
                                            {visibleColumns.description && <th>Description</th>}
                                            {visibleColumns.User && <th style={{ width: '6vw' }}>User</th>}
                                            {visibleColumns.duration && <th style={{ width: '6vw' }}>Duration</th>}
                                            {visibleColumns.startTime && <th>Start Time</th>}
                                            {visibleColumns.endTime && <th>End Time</th>}
                                            {visibleColumns.measurements && <th>Measurements</th>}
                                            {visibleColumns.additionalFields && <th>Additional Fields</th>}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {detailedData.length > 0 ? (
                                            detailedData
                                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                                .map((item, index) => (
                                                    <tr key={index}>
                                                        {visibleColumns.date && (
                                                            <td style={{ width: '100px' }}>
                                                                {moment(item.date).format('DD/MM/YYYY')}
                                                            </td>
                                                        )}
                                                        {visibleColumns.project && <td>{item.projectName}</td>}
                                                        {visibleColumns.task && <td>{item.taskName}</td>}
                                                        {visibleColumns.description && (
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>{item.description}</Tooltip>}>
                                                                <td>
                                                                    <span>{item.description || 'No description'}</span>
                                                                </td>
                                                            </OverlayTrigger>
                                                        )}
                                                        {visibleColumns.User && <td>{item.userName}</td>}
                                                        {visibleColumns.duration && (
                                                            <td style={{ width: '80px' }}>
                                                                {typeof item.duration === 'string' && item.duration.includes(':') ? (
                                                                    // If duration is in HH:MM format, display as is
                                                                    item.duration
                                                                ) : (
                                                                    // If duration is in seconds, convert to HH:MM format
                                                                    `${Math.floor(item.duration / 60)}h ${item.duration % 60}m`
                                                                )}
                                                            </td>
                                                        )}
                                                        {visibleColumns.startTime && <td>{item.startTime}</td>}
                                                        {visibleColumns.endTime && <td>{item.endTime}</td>}
                                                        {visibleColumns.measurements && (
                                                            <td>
                                                                {item.measurements.map((measurement, i) => (
                                                                    <React.Fragment key={i}>
                                                                        <strong>{measurement.measurementName}</strong>: {measurement.value}
                                                                        {i < item.measurements.length - 1 && ', '}
                                                                    </React.Fragment>
                                                                ))}
                                                            </td>
                                                        )}
                                                        {visibleColumns.additionalFields && (
                                                            <td>
                                                                {item.additionalFields.map((field, i) => (
                                                                    <React.Fragment key={i}>
                                                                        <strong>{field.additionalFieldName}</strong>: {field.newValue}
                                                                        {i < item.additionalFields.length - 1 && ', '}
                                                                    </React.Fragment>
                                                                ))}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan={8} className="text-center">No data available for the selected filters.</td>
                                            </tr>
                                        )}
                                    </tbody>


                                </Table>
                            </div>
                        </Tab>
                        <Tab eventKey="wsr" title="WSR">
                            <div id="wsr-tab-content">
                                <WeeklyStatusReport
                                    selectedProject={filters.project}
                                    selectedUsers={filters.team}
                                    selectedTasks={filters.task}
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    selectedClient={filters.client}
                                />
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default ReportsPage;