import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import uri from '../../config';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import SpurTestLogo from "./verifyemail1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import spurTrack from "../../images/Spurtracklogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Getstartedverification = () => {
  const [data, setData] = useState({ email: "" });
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal state
  const navigate = useNavigate();
  const location = useLocation();

  // Check if it's an invited user by checking the query parameter in the URL
  const isInvitedUser = location.search.includes("email");

  useEffect(() => {
    // Auto-populate the email field if it's an invited user
    if (isInvitedUser) {
      const searchParams = new URLSearchParams(location.search);
      const invitedEmail = searchParams.get("email");
      setData({ ...data, email: invitedEmail });
    }
  }, [isInvitedUser, location.search]);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let url;
      if (isInvitedUser) {
        url = `${uri}/api/isinvited`;
      } else {
        url = `${uri}/api/signup-otp-verify`;
      }

      const { data: res } = await axios.post(url, data);
      localStorage.setItem("signupEmail", data.email); // Store email in local storage
      setMsg(res.message);
      setError("");

      // Redirect based on user type

      if (isInvitedUser) {
        navigate("/signup-otp-verify");
      } else {
        navigate("/signup-otp-verify");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);

      setTimeout(() => {
        setError("");
        setMsg("");
      }, 5000);

      if (!error) {
        setData({ email: "" });
      }
    }
  };

  const goHome = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <div className="vh-100 vw-50 d-flex justify-content-center align-items-center">
      <div className="w-70 d-flex flex-column justify-content-center align-items-center p-4">
        <img src={SpurTestLogo} alt="SpurTest Logo" className="img-fluid mb-4" style={{ height: "70vh" }} />
        <div className="mt-4">
        </div>
      </div>
      <div className="w-50 d-flex flex-column align-items-center p-4">
        <img src={spurTrack} alt="ValidatePro Logo" className="img-fluid mb-4" style={{ width: '10vw' }} />
        <div className="w-80">
          <div className="text-center">
            <h4 className="mb-4">Verify Email to Get Started</h4>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                value={data.email}
                required
                readOnly={isInvitedUser}
                style={{ border: 'none', backgroundColor: '#edf5f3' }}
              />
            </Form.Group>
            {error && <div className="alert alert-danger" id="Error-sending-mail">{error}</div>}
            {msg && <div className="alert alert-success" id="Success-sending-mail">{msg}</div>}
            <Button style={{
              color: "#F0F3FA",
              backgroundColor: "#26344b",
              outlineColor: 'none',
              border: 'none'
            }} type="submit" className="w-100" disabled={loading}>
              {loading ? "Verifying..." : "Verify"}
            </Button>
          </Form>
          <h6 style={{ textAlign: 'center' }}>
            <Link to="/login" className="text-decoration-none">
              Already a member?
            </Link>
          </h6>
        </div>
      </div>

      {/* Modal for personal or temporary email restriction */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="custom-modal"
      >
        <Modal.Header className="border-0 p-0" closeButton>
          <div className="w-100 text-center mt-4">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="3x"
              className="text-warning"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 className="mt-3 mb-2">Invalid Email Address</h5>
          <p className="mb-4">
            Please use your company-provided email address or a valid email address to create an account.
            Personal and temporary email addresses are not allowed.
          </p>
          <Button
            variant="primary"
            className="w-50 mx-auto"
            onClick={goHome}
            style={{
              color: "#F0F3FA",
              backgroundColor: "#26344b",
              outlineColor: 'none',
              border: 'none'
            }}
          >
            Return to Home
          </Button>
        </Modal.Body>
      </Modal>
      <style jsx="true">{`
        .custom-modal .modal-content {
          border-radius: 20px;
          padding: 20px;
        }
        .custom-modal h5 {
          font-weight: 600;
          font-size: 1.2rem;
          color: #333333;
        }
        .custom-modal p {
          font-size: 1rem;
          color: #666666;
        }
        .custom-modal .modal-body {
          padding-top: 0;
        }
      `}</style>
    </div>
  );
};

export default Getstartedverification;
