// AboutUs/index.jsx

import React from 'react';
import styles from './styles.module.css';

const AboutUs = () => {
  return (
    <div id='Main-Container' className={styles.aboutUsContainer}>
      <h1>About Us</h1>
      <p >This is the About Us page content.</p>
    </div>
  );
};

export default AboutUs;
