let PRODUCTIONURI= "https://spurtrack.com";
let QAURI= "http://13.201.226.1:8080"
let DEVELOPMENTURI= "http://localhost:5009";
let ENVIRONMENT= "production";
let uri;

switch (ENVIRONMENT) {
  case 'production':
    uri = PRODUCTIONURI;
    break;
  case 'qa':
    uri = QAURI;
    break;
  case 'development':
    uri = DEVELOPMENTURI;
    break;
  default:
    console.warn('Unknown environment specified, using development URI.');
    uri = DEVELOPMENTURI; // Default to development URI
    break;
}

export default uri;