import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import uri from '../../config';
import styles from './styles.module.css';

const ContactUs = () => {
  const [showModal, setShowModal] = useState(true); // Initially set to true to show the modal
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    jobRole: '',
    companyDomain: '',
    goals: '',
  });
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    return () => {
      document.body.style.overflow = 'unset'; // Restore scrolling when modal is closed
    };
  }, []);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const url = `${uri}/api/contactus`;
      const { data: res } = await axios.post(url, data);
      setMsg(res.message);
      setError('');
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError('');
        setMsg('');
      }, 5000);
      if (!error) {
        setData({ email: '', goals: '', firstName: '', lastName: '', companyDomain: '', jobRole: '' });
      }
    }
  };



  return (
    <Modal show={showModal} size='lg'  centered>
      <Modal.Header >
        <Modal.Title>Contact Us</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{height:'70vh'}}  className='text-center'>
        <Form onSubmit={handleSubmit} style={{width:'auto'}}>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="First name"
              name="firstName"
              onChange={handleChange}
              value={data.firstName}
              className={styles.inputField}
              required
            />
            <Form.Control
              type="text"
              placeholder="Last name"
              name="lastName"
              onChange={handleChange}
              value={data.lastName}
              className={styles.inputField}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder="Email address"
              name="email"
              onChange={handleChange}
              value={data.email}
              className={styles.inputField}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Job role"
              name="jobRole"
              onChange={handleChange}
              value={data.jobRole}
              className={styles.inputField}
              required
            />
            <Form.Control
              type="text"
              placeholder="Company name"
              name="companyDomain"
              onChange={handleChange}
              value={data.companyDomain}
              className={styles.inputField}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Please tell us a bit about your goals, what are you hoping to achieve with Survicate?"
              onChange={handleChange}
              name="goals"
              value={data.goals}
              className={styles.inputField}
              required
            />
          </Form.Group>
          {error && <Alert variant="danger">{error}</Alert>}
          {msg && <Alert variant="success">{msg}</Alert>}
          <Button type="submit" variant="primary" disabled={loading}>
            {loading ? 'Sending...' : 'Submit'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUs;