import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar";
import styles from "./main.css";
import Header from "../Header/index";
import 'bootstrap/dist/css/bootstrap.min.css';

const Main = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [error, setError] = useState("");
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true); // Track sidebar state

  useEffect(() => {
    const savedTasks = JSON.parse(localStorage.getItem("tasks")) || [];
    setTasks(savedTasks);
  }, []);

  const handleAddTask = () => {
    if (newTask.trim() === "") {
      setError("Task cannot be empty");
      return;
    }
    const updatedTasks = [...tasks, { text: newTask, completed: false }];
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
    setNewTask("");
    setError("");
  };

  const handleToggleTask = (index) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
  };

  const handleDeleteTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
    localStorage.setItem("tasks", JSON.stringify(updatedTasks));
  };

  const handleSidebarToggle = () => {
    setIsSidebarExpanded(!isSidebarExpanded);  // Toggle the sidebar state
  };

  return (
    <div>
      {/* Header Section */}
      <Header />

      {/* Sidebar and Content */}
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <Sidebar isExpanded={isSidebarExpanded} onToggle={handleSidebarToggle} />
        {/* Pass state and toggle function */}

        <div
          style={{
            marginLeft: isSidebarExpanded ? '250px' : '80px', // Dynamically adjust margin for sidebar
            paddingTop: '60px', // Adjust for header height (60px matches the header height)
            transition: 'margin 0.3s ease' // Smooth transition for sidebar collapse
          }}
        >
          <div className="container mt-4">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-9">
                <h2>Tasks</h2>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Task"
                    value={newTask}
                    onChange={(e) => setNewTask(e.target.value)}
                  />
                  <button
                    className="btn btn-outline-primary"
                    onClick={handleAddTask}
                  >
                    Add Task
                  </button>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <ul className="list-group">
                  {tasks.map((task, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <span
                        style={{
                          textDecoration: task.completed ? "line-through" : "none",
                        }}
                      >
                        {task.text}
                      </span>
                      <div>
                        <button
                          className="btn btn-outline-success btn-sm me-2"
                          onClick={() => handleToggleTask(index)}
                        >
                          {task.completed ? "Undo" : "Complete"}
                        </button>
                        <button
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleDeleteTask(index)}
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
