import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import uri from '../../config';
import LoginLogo from "./signup.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import spurTrack from "../../images/Spurtracklogo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = `${uri}/api/auth`;
      const { data: { data: { token, organization, userName, redirectPath,role} } } = await axios.post(url, data);
      localStorage.setItem("token", token);
      localStorage.setItem("email", data.email);
      localStorage.setItem("organization", organization);
      localStorage.setItem("userName", userName);
      localStorage.setItem("role",role );
      if (redirectPath && redirectPath !== '/') {
        window.location = redirectPath;
      } else {
        console.error("Received undefined redirectPath");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
    <style>
      {`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
        
        /* Ensure icons are the same size as in Signup */
        .input-group-prepend, .input-group-text {
          width: 50px; /* Match the size in Signup */
          height: 50px; /* Match the size in Signup */
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px; /* Consistent with Signup */
          background-color: #fff; /* Ensure background color is consistent */
        }
        
        /* Add space between icon and input field */
        .form-control {
          margin-left: 15px; /* Increased margin to add space */
        }
      `}
    </style>
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-body d-flex">
          <div className="w-50 d-flex flex-column justify-content-center align-items-center p-4 mt-4">
            <img src={LoginLogo} alt="ATAS" className="img-fluid mb-4" />
            <div className="mt-4">
              <h6>
                <Link to="/signup-email-verify" className="text-decoration-none">
                  Create an account
                </Link>
              </h6>
            </div>
          </div>
          <div className="w-50 d-flex flex-column align-items-center p-4">
            <img src={spurTrack} alt="spurTrack Logo" className="img-fluid mb-1" style={{ width: '7vw' }} />
            <form className="w-100" onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} size="lg" /></span>
                  </div>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                    required
                    className="form-control ms-2"
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text"><FontAwesomeIcon icon={faLock} size="lg" /></span>
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    value={data.password}
                    required
                    className="form-control ms-2"
                  />
                </div>
              </div>
              <div className="form-group form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={showPassword}
                  onChange={togglePasswordVisibility}
                  id="showPasswordCheck"
                />
                <label className="form-check-label" htmlFor="showPasswordCheck">
                  Show Password
                </label>
              </div>
              <Link to="/forgot-password" className="mb-3 d-block text-decoration-none">
                Forgot Password?
              </Link>
              {error && <div className="alert alert-danger">{error}</div>}
              <button
                type="submit"
                className="btn btn-primary w-100 mb-2"
                disabled={loading}
              >
                {loading ? "Signing..." : "Sign In"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Login;
