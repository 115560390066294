import React, { useState } from "react";
import {
  FaBuilding,
  FaClipboardList,
  FaClock,
  FaTachometerAlt,
  FaChartBar,
  FaCog,
  FaHouseUser,
} from "react-icons/fa";
import { PiTargetBold } from "react-icons/pi";
import { SiGoogleanalytics } from "react-icons/si";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sidebar.css";
import logo from "./Spurtracklogo.png";

const Sidebar = ({ onMySpaceClick }) => {
  const [menuSelected, setMenuSelected] = useState("");

  // Fetch role from local storage
  const role = localStorage.getItem("role");

  const handleMenuItemClick = (menu) => {
    setMenuSelected(menu === menuSelected ? "" : menu);
  };

  return (
    <div className="sidebar">
      {/* Logo Section */}
      <div className="top">
        <div className="logo">
          <img src={logo} alt="Logo" className="menu-logo" />
        </div>

        {/* Main Links */}
        <div className="menu-links main-links">
          <ul>
            {/* Organization Menu */}
            <li>
              <button onClick={() => handleMenuItemClick("Organization")}>
                <FaBuilding className="menu-icon" />
                <span className="menu-text">Organization</span>
              </button>
            </li>

            {/* Project Link */}
            <li>
              <NavLink className="navlink" to="/orgdashboard">
                <button>
                  <FaClipboardList className="menu-icon" />
                  <span className="menu-text">Project</span>
                </button>
              </NavLink>
            </li>

            {/* Timesheet Link */}
            <li>
              <NavLink className="navlink" to="/calendar">
                <button>
                  <FaClock className="menu-icon" />
                  <span className="menu-text">Timesheet</span>
                </button>
              </NavLink>
            </li>

            {/* Milestone Link */}
            <li>
              <NavLink className="navlink" to="/milestone">
                <button>
                  <PiTargetBold className="menu-icon" />
                  <span className="menu-text">Milestone</span>
                </button>
              </NavLink>
            </li>

            {/* Analytics Link */}
            <li>
              <NavLink className="navlink" to="/orgreports">
                <button>
                  <SiGoogleanalytics className="menu-icon" />
                  <span className="menu-text">Analytics</span>
                </button>
              </NavLink>
            </li>

            {/* My Space Link */}


            <li>
              <NavLink
                className="navlink"
                to="/myspace"
                onClick={onMySpaceClick} // Call the prop function when clicked
              >
                <button>
                  <FaHouseUser className="menu-icon" />
                  <span className="menu-text">My Space</span>
                </button>
              </NavLink>
            </li>



          </ul>
        </div>
      </div>

      {/* Submenu Overlay */}
      <div className={`submenu ${menuSelected ? "open" : ""}`}>
        {menuSelected === "Organization" && (
          <div>
            <h5>Organization</h5>
            <ul>
              {/* Organization Dashboard */}
              <li>
                <NavLink to="/orgdashboard">
                  <button>
                    <FaTachometerAlt className="menu-icon" />
                    <span>Dashboard</span>
                  </button>
                </NavLink>
              </li>

              {/* Settings - Only for SuperAdmin */}
              {role === "SuperAdmin" && (
                <li>
                  <NavLink to="/orgsetup">
                    <button>
                      <FaCog className="menu-icon" />
                      <span>Settings</span>
                    </button>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
