import { useState } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import uri from '../../config';
import SpurQLabsLogo from "./SpurQLabs.png";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Start loading
		setLoading(true);
		try {
			const url = `${uri}/api/password-reset`;
			const { data } = await axios.post(url, { email });
			setMsg(data.message);
			setError("");
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		} finally {
			setLoading(false);
			// Set a timeout to clear the messages after 5000 milliseconds (5 seconds)
			setTimeout(() => {
				setError("");
				setMsg("");
			}, 5000);
		}
	};

	return (
		<>
			<div>
				<header id="Header" className={styles.header}>
					<div id="Logo" className={styles.logoContainer}>
						<Link to="/home">
							<img
								src={SpurQLabsLogo} // Updated path for SpurQLabsLogo
								alt="Company Logo"
								className={styles.logo}
							/>
						</Link>
					</div>
					<div className={styles.heading}>
						<h1>Forgot Password</h1>
					</div>
				</header>
			</div>
			<div id="Main-Container" className={styles.container}>

				<form className={styles.form_container} onSubmit={handleSubmit}>
					<h1>Forgot Password</h1>
					<input
						type="email"
						placeholder="Email"
						name="email"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
						className={styles.input}
					/>
					{error && <div id="Error" className={styles.error_msg}>{error}</div>}
					{msg && <div id="Success" className={styles.success_msg}>{msg}</div>}
					<button type="submit" className={styles.green_btn} disabled={loading}>
						{loading ? "Submitting..." : "Submit"}
					</button>
				</form>
			</div>
		</>
	);
};

export default ForgotPassword;
