import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import uri from "../../config";
import Sidebar from "../sidebar";
import Header from "../Header/index";
import style from "../Timesheet/timesheet.css";
import InfoImg from "./InfoImg2.png";
import { ToastContainer, toast } from "react-toastify"; // Importing toast components
import "react-toastify/dist/ReactToastify.css"; // Importing toast CSS
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineHome } from "react-icons/hi";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const TaskForm = (entry) => {
  const [projects, setProjects] = useState([]); // State to hold the project list
  const [tasks, setTasks] = useState([]); // State to hold the tasks based on the selected project
  const [measurements, setMeasurements] = useState([]); // New state for measurements
  const [formData, setFormData] = useState({
    selectedProject: "",
    selectedTask: "",
    description: "",
    startTime: { hour: "", minute: "00" },
    endTime: { hour: "", minute: "00" },
    duration: "",
    measurements: [],
    additionalFields: [], // New additional fields state
  });
  const [measurementUnits, setMeasurementUnits] = useState([]); // State to hold measurement units for validation
  const [savedData, setSavedData] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // For popup visibility
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isEditing, setIsEditing] = useState(false); // Track if fields are editable
  const [editData, setEditData] = useState({ ...entry });
  const navigate = useNavigate(); // Initialize useNavigate
  let organizationName = localStorage.getItem("organization");
  let user = localStorage.getItem("userName");
  let useremail = localStorage.getItem("email");
  let selectedDate = localStorage.getItem("selectedDate");

  // Fetch projects on component load
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const organizationId = localStorage.getItem("organization");
        const email = localStorage.getItem("email");

        // Fetch Projects
        const response = await axios.get(`${uri}/api/projects/timesheet`, {
          params: { organizationId, email }
        });

        setProjects(response.data.data); // Assuming the API response structure
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);
  useEffect(() => {
    const fetchTasksForEdit = async () => {
      if (editData.projectName) {
        try {
          const response = await axios.get(
            `${uri}/api/addtask/${editData.projectName}`
          );
          setTasks(response.data); // Assuming `tasks` is a shared state for tasks
        } catch (error) {
          console.error("Error fetching tasks during edit:", error);
        }
      }
    };

    fetchTasksForEdit();
  }, [editData.projectName]); // Runs when projectName in editData changes

  // Fetch tasks when a project is selected
  useEffect(() => {
    const fetchTasks = async () => {
      if (formData.selectedProject) {
        try {
          const response = await axios.get(
            `${uri}/api/addtask/${formData.selectedProject}`
          );
          setTasks(response.data);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        }
      }
    };

    fetchTasks();
  }, [formData.selectedProject]);
  useEffect(() => {
    const fetchMeasurementsForEdit = async () => {
      if (editData.taskName) {
        try {
          // Fetch measurements
          const measurementResponse = await axios.post(`${uri}/api/measurements`, {
            taskName: editData.taskName,
          });

          setMeasurements(measurementResponse.data);
          setMeasurementUnits(measurementResponse.data.map(measurement => measurement.measurementUnit));

          // Populate measurements in editData if not already present
          setEditData(prevState => ({
            ...prevState,
            measurements: measurementResponse.data.map((measurement, index) =>
              prevState.measurements[index] || { measurementName: measurement.measurementName, value: "" }
            ),
          }));
        } catch (error) {
          console.error("Error fetching measurements for edit:", error);
        }
      }
    };

    fetchMeasurementsForEdit();
  }, [editData.taskName]); // Triggered when taskName changes in editData

  // Fetch measurements based on the selected task
  useEffect(() => {
    const fetchMeasurements = async () => {
      if (formData.selectedTask) {
        try {
          // Fetch measurements (already existing)
          const measurementResponse = await axios.post(`${uri}/api/measurements`, {
            taskName: formData.selectedTask,
          });
          setMeasurements(measurementResponse.data);
          setMeasurementUnits(measurementResponse.data.map(measurement => measurement.measurementUnit));

          // Initialize measurements in formData
          setFormData(prevState => ({
            ...prevState,
            measurements: Array(measurementResponse.data.length).fill(""),
          }));

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchMeasurements();
  }, [formData.selectedTask]);
  useEffect(() => {
    const fetchAdditionalFieldsForEdit = async () => {
      if (editData.taskName) {
        try {
          // Fetch additional fields
          const response = await axios.post(`${uri}/api/measurements/additionalfields`, {
            taskName: editData.taskName,
          });

          setAdditionalFields(response.data || []);

          // Populate additional fields in editData if not already present
          setEditData(prevState => ({
            ...prevState,
            additionalFields: response.data.map((field, index) =>
              prevState.additionalFields[index] || { additionalFieldName: field.additionalFieldName, newValue: "" }
            ),
          }));
        } catch (error) {
          console.error("Error fetching additional fields for edit:", error);
        }
      }
    };

    fetchAdditionalFieldsForEdit();
  }, [editData.taskName]); // Triggered when taskName changes in editData


  // Fetch additional fields based on the selected task
  useEffect(() => {
    const fetchAdditionalFields = async () => {
      if (formData.selectedTask) {
        try {
          const response = await axios.post(`${uri}/api/measurements/additionalfields`, {
            taskName: formData.selectedTask,
          });

          // Set additional fields or an empty array
          setAdditionalFields(response.data || []);

          // Initialize additional fields in formData based on fetched fields
          setFormData((prevState) => ({
            ...prevState,
            additionalFields: Array(response.data.length).fill(""), // Initialize with empty values
          }));
        } catch (error) {
          console.error("Error fetching additional fields:", error);
        }
      }
    };

    fetchAdditionalFields();
  }, [formData.selectedTask]);



  // Fetch timesheet entries for the selected project and date
  useEffect(() => {
    fetchTimesheetEntries();
  }, [organizationName, selectedDate, user, useremail]);

  const fetchTimesheetEntries = async () => {
    try {
      const response = await axios.get(`${uri}/api/timesheettask`, {
        params: {
          userName: user,
          email: useremail,
          date: selectedDate,
          organization: organizationName,
        },
      });
      setFetchedData(response.data);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }
  };


  const calculateDuration = (start, end) => {
    if (start && end) {
      const startDate = new Date(`1970-01-01T${start}:00`);
      const endDate = new Date(`1970-01-01T${end}:00`);
      const diffMs = endDate - startDate;
      const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
      const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
      return `${diffHrs}h ${diffMins}m`;
    }
    return "";
  };

  const validateTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return (
      hours >= 0 &&
      hours < 24 &&
      minutes >= 0 &&
      minutes < 60 &&
      minutes % 15 === 0
    );
  };

  const handleInputChange = (field, value) => {
    const updatedFormData = { ...formData, [field]: value };

    if (field === "startTime" || field === "endTime") {
      if (
        !validateTime(updatedFormData.startTime) ||
        !validateTime(updatedFormData.endTime)
      ) {
        updatedFormData.duration = "";
      } else {
        updatedFormData.duration = calculateDuration(
          updatedFormData.startTime,
          updatedFormData.endTime
        );
      }
    }

    if (field === "selectedProject") {
      updatedFormData.description = "";
      updatedFormData.selectedTask = ""; // Reset task selection when project changes
      updatedFormData.measurements = []; // Clear measurements
      updatedFormData.additionalFields = []; // Clear additional fields
    }

    setFormData(updatedFormData);
  };
  ;

  const handleAdditionalFieldChange = (index, newValue) => {
    setFormData((prevState) => {
      const newAdditionalFields = [...prevState.additionalFields];
      newAdditionalFields[index] = {
        ...newAdditionalFields[index],
        newValue: newValue, // Ensure it's a plain string
      };
      return { ...prevState, additionalFields: newAdditionalFields };
    });
  };





  const handleSessionDeselect = () => {
    // Deselect session when time is changed
    setFormData(prevState => ({
      ...prevState,
      session: "" // Clears the session field
    }));
  };




  // This function should properly update formData.measurements
  const handleMeasurementChange = (index, value) => {
    setFormData((prevData) => {
      const updatedMeasurements = [...(prevData.measurements || [])];
      updatedMeasurements[index] = {
        ...updatedMeasurements[index],
        value,
      };
      return { ...prevData, measurements: updatedMeasurements };
    });
  };



  const handleAdditionalFieldEdit = (index) => (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => {
      const updatedFields = [...prevData.additionalFields];
      updatedFields[index] = {
        ...updatedFields[index],
        [name]: value,  // Correctly referencing "newValue"
      };
      return { ...prevData, additionalFields: updatedFields };
    });
  };

  const handleMeasurementEdit = (index) => (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => {
      const updatedMeasurements = [...prevData.measurements];
      updatedMeasurements[index] = {
        ...updatedMeasurements[index],
        [name]: value,  // Correctly referencing "value"
      };
      return { ...prevData, measurements: updatedMeasurements };
    });
  };


  const calculateTotalDuration = () => {
    let totalMinutes = 0;
    savedData.forEach(entry => {
      if (entry.duration) {
        // Split "HH:MM" and convert to numbers
        const [hours, minutes] = entry.duration.split(":").map(Number);
        totalMinutes += hours * 60 + minutes;
      }
    });
    return totalMinutes;
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleWorkTypeChange = (type) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      workType: type,
    }));
  };

  const handleDayPartSelection = (session, newFormData) => {
    // Update the session and related data (such as time settings).
    if (session === "Full Day") {
      setFormData({
        ...newFormData, // Preserve any existing form data
        startTimeHours: 9,
        startTimeMinutes: 0,
        endTimeHours: 18,
        endTimeMinutes: 0,
        duration: "8:00",
        isFullDay: true,
        isFirstHalf: false,
        isSecondHalf: false,
      });
    } else if (session === "First Half") {
      setFormData({
        ...newFormData, // Preserve any existing form data
        startTimeHours: 9,
        startTimeMinutes: 0,
        endTimeHours: 13,
        endTimeMinutes: 0,
        duration: "4:00",
        isFullDay: false,
        isFirstHalf: true,
        isSecondHalf: false,
      });
    } else if (session === "Second Half") {
      setFormData({
        ...newFormData, // Preserve any existing form data
        startTimeHours: 14,
        startTimeMinutes: 0,
        endTimeHours: 18,
        endTimeMinutes: 0,
        duration: "4:00",
        isFullDay: false,
        isFirstHalf: false,
        isSecondHalf: true,
      });
    }
  };


  // Session change handler to handle radio button changes
  const handleSessionChange = (session) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, session: session }; // Preserve other form data while changing the session.
      handleDayPartSelection(session, newFormData); // Pass the updated session and form data to handleDayPartSelection.
      return newFormData;
    });
  };

  // Submit the edited data to the API
  const handleSave = async () => {
    try {





      // Ensure valid inputs with default values
      const startTimeHours = String(formData.startTimeHours || "00").padStart(2, '0');
      const startTimeMinutes = String(formData.startTimeMinutes || "00").padStart(2, '0');
      const endTimeHours = String(formData.endTimeHours || "00").padStart(2, '0');
      const endTimeMinutes = String(formData.endTimeMinutes || "00").padStart(2, '0');

      // Construct the start and end times in HH:MM format
      const startTime = `${startTimeHours}:${startTimeMinutes}`;
      const endTime = `${endTimeHours}:${endTimeMinutes}`;

      // Convert the times to minutes for duration calculation
      const startTimeInMinutes = parseInt(startTimeHours, 10) * 60 + parseInt(startTimeMinutes, 10);
      const endTimeInMinutes = parseInt(endTimeHours, 10) * 60 + parseInt(endTimeMinutes, 10);

      // Calculate the duration in minutes
      let durationInMinutes = endTimeInMinutes - startTimeInMinutes;
      if (durationInMinutes < 0) {
        // Handle overnight duration
        durationInMinutes += 24 * 60;
      }

      // Convert duration back to HH:MM format
      const durationHours = Math.floor(durationInMinutes / 60).toString().padStart(2, '0');
      const durationMinutes = (durationInMinutes % 60).toString().padStart(2, '0');
      const duration = `${durationHours}:${durationMinutes}`;

      // Format measurements
      const formattedMeasurements = (formData.measurements || []).map((measurement, index) => ({
        measurementName: measurements[index]?.measurementName || `Measurement ${index + 1}`,
        value: measurement?.value || "", // Safely access and default values
      }));

      // Format additional fields
      const validAdditionalFields = (formData.additionalFields || []).map((field, index) => ({
        additionalFieldName: additionalFields[index]?.additionalFieldName || `Field ${index + 1}`,
        newValue: field?.newValue || "", // Safely access and default values
      }));
      if (!formData.selectedProject) {
        toast.error("Please Select a Project");
        return;
      }
      // Validation for required fields
      if (!formData.selectedTask) {
        toast.error("Please Select A Task");
        return;
      }
      if (!formData.description?.trim()) {
        toast.error("Description is required.");
        return;
      }

      // Validate required additional fields
      for (let i = 0; i < additionalFields.length; i++) {
        const field = additionalFields[i];
        const value = formData.additionalFields?.[i]?.newValue || "";

        if (field.isRequired && !value.trim()) {
          toast.error(`Please fill in the required field: ${field.additionalFieldName}`);
          return; // Stop the save process
        }
      }


      // Validate required additional fields
      for (let i = 0; i < additionalFields.length; i++) {
        const field = additionalFields[i];
        const value = formData.additionalFields?.[i]?.newValue || "";

        if (field.isRequired && !value.trim()) {
          toast.error(`Please fill in the required Custom field: ${field.additionalFieldName}`);
          return; // Prevent form submission
        }
      }

      // Save data with the correctly formatted time fields
      const dataToSave = {
        organization: organizationName,
        userName: user,
        email: useremail,
        taskName: formData.selectedTask,
        description: formData.description || "",
        date: selectedDate,
        TimesheetDate: selectedDate,
        startTime, // Save in HH:MM format
        endTime,   // Save in HH:MM format
        duration,  // Save in HH:MM format
        measurements: formattedMeasurements,
        workType: formData.workType || "Default",
        session: formData.session || "Default",
        additionalFields: validAdditionalFields,
        projectName: formData.selectedProject,
      };

      // Post the data to the server
      await axios.post(`${uri}/api/timesheettask`, dataToSave);

      // Reset and provide feedback
      toast.success("Details saved successfully!");
      await fetchTimesheetEntries(); // Refresh the entries
      handleCancel(); // Clear the form or close the modal
    } catch (error) {
      if (error.response) {
        // Handle backend validation errors
        if (error.response.status === 400 && error.response.data.message.includes('Duplicate time entry')) {
          toast.error("Duplicate time entry detected. Please check your times and try again.");
        } else {
          toast.error(error.response.data.message || "Error saving details. Please try again later.");
        }
      } else {
        console.error("Error saving data:", error);
        toast.error("Unexpected error. Please try again later.");
      }
    }
  };









  const handleCancel = () => {
    setFormData({
      selectedProject: "",
      selectedTask: "",
      description: "",
      startTime: "",
      endTime: "",
      duration: "",
      measurements: [],
      additionalFields: [],
    });
    setMeasurements([]);
    setAdditionalFields([]);
    setIsPopupOpen(false);
  };

  const handleEdit = (entry) => {
    // Extract hours and minutes from startTime and endTime strings
    const [startHours, startMinutes] = entry.startTime.split(":").map(Number);
    const [endHours, endMinutes] = entry.endTime.split(":").map(Number);

    setIsEditing(true);
    setEditData({
      ...entry, // Spread the selected entry's current data into the editData state
      startTimeHours: startHours || "00", // Set startTimeHours
      startTimeMinutes: startMinutes || "00", // Set startTimeMinutes
      endTimeHours: endHours || "00", // Set endTimeHours
      endTimeMinutes: endMinutes || "00", // Set endTimeMinutes
      measurements: entry.measurements || [], // Ensure measurements and additionalFields are non-null arrays
      additionalFields: entry.additionalFields || [],
    });
  };


  const handleSaveEdit = async () => {
    if (!editData) {
      console.error("editData is null or undefined");
      return;
    }
    try {
      // Reconstruct startTime and endTime
      const updatedStartTime = `${editData.startTimeHours}:${editData.startTimeMinutes}`;
      const updatedEndTime = `${editData.endTimeHours}:${editData.endTimeMinutes}`;

      const updatedData = {
        ...editData,
        startTime: updatedStartTime,
        endTime: updatedEndTime,
      };

      const response = await fetch(`${uri}/api/timesheettask/${editData._id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.message && errorData.message.includes('Duplicate time entry')) {
          toast.error("Duplicate time entry detected. Please check your times and try again.");
          return; // Prevent further execution if duplicate error is encountered
        }
        throw new Error("Failed to update the timesheet entry");
      }

      const updatedEntry = await response.json();

      // Update the state
      setFetchedData((prevData) =>
        prevData.map((entry) =>
          entry._id === editData._id ? updatedEntry.data : entry
        )
      );
      setIsEditing(false);

      toast.success("Details updated successfully!");
    } catch (error) {
      console.error("Error updating timesheet:", error);
    }
  };





  const handleDelete = async (entryId) => {
    try {
      await axios.delete(`${uri}/api/timesheettask/${entryId}`);
      console.log("Deleting entry with ID:", entryId);
      setFetchedData(fetchedData.filter((entry) => entry._id !== entryId));
      toast.success("Entry Deleted successfully!");

    } catch (error) {
      console.error("Error deleting timesheet entry:", error);
    }
  };

  const formatDateForInput = (isoDateString) => {
    const date = new Date(isoDateString);
    if (isNaN(date.getTime())) {
      return ""; // Return empty string if date is invalid
    }
    return date.toISOString().slice(0, 16); // Format to 'YYYY-MM-DDTHH:MM'
  };
  const convertToMinutes = (hours, minutes) => {
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const handleTimeChange = (field, value) => {
    // Update the appropriate time field in formData
    const updatedFormData = { ...formData, [field]: value || "00" };

    // Parse start and end times as integers
    const startHours = parseInt(updatedFormData.startTimeHours || 0, 10);
    const startMinutes = parseInt(updatedFormData.startTimeMinutes || 0, 10);
    const endHours = parseInt(updatedFormData.endTimeHours || 0, 10);
    const endMinutes = parseInt(updatedFormData.endTimeMinutes || 0, 10);

    // Create Date objects for start and end times
    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHours, endMinutes, 0, 0);

    // Calculate duration in milliseconds
    let durationInMs = endDate - startDate;

    // Handle cases where the end time is earlier than the start time (e.g., overnight shift)
    if (durationInMs < 0) {
      durationInMs += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
    }

    // Convert duration to hours and minutes
    const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
    const durationHours = Math.floor(durationInMinutes / 60);
    const durationMinutes = durationInMinutes % 60;

    // Format duration as "HH:MM"
    const durationFormatted = `${durationHours}:${durationMinutes < 10 ? '0' : ''}${durationMinutes}`;

    // Update formData with the calculated duration
    updatedFormData.duration = durationFormatted;

    setFormData(updatedFormData);
  };

  const handleEditTimeChange = (field, value) => {
    // Update the specific time field in editData
    const updatedEditData = { ...editData, [field]: value || "00" };

    // Parse start and end times as integers
    const startHours = parseInt(updatedEditData.startTimeHours || 0, 10);
    const startMinutes = parseInt(updatedEditData.startTimeMinutes || 0, 10);
    const endHours = parseInt(updatedEditData.endTimeHours || 0, 10);
    const endMinutes = parseInt(updatedEditData.endTimeMinutes || 0, 10);

    // Create Date objects for start and end times
    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHours, endMinutes, 0, 0);

    // Calculate duration in milliseconds
    let durationInMs = endDate - startDate;

    // Handle overnight shifts (end time earlier than start time)
    if (durationInMs < 0) {
      durationInMs += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
    }

    // Convert duration to hours and minutes
    const durationInMinutes = Math.floor(durationInMs / (1000 * 60));
    const durationHours = Math.floor(durationInMinutes / 60);
    const durationMinutes = durationInMinutes % 60;

    // Format duration as "HH:MM"
    const durationFormatted = `${durationHours}:${durationMinutes < 10 ? '0' : ''}${durationMinutes}`;

    // Update editData with the calculated duration
    updatedEditData.duration = durationFormatted;

    // Update the editData state
    setEditData(updatedEditData);
  };



  return (
    <div>
      <Header />
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <Sidebar
          isExpanded={isSidebarExpanded}
          setIsExpanded={setIsSidebarExpanded}
        />

        <div
          className="container "
          style={{
            marginTop:'6vw',
            marginLeft:'16vw',
            marginRight:'3vw'
            
          }}
        >
          <h5>{selectedDate}</h5>

          {fetchedData.length === 0 ? (

            <div
              className="empty-state d-flex flex-column align-items-center "
              style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
            >
              <Button className="btn btn-btn-primary" onClick={() => navigate('/calendar')}
                style={{ marginRight: '75vw' }}>
                Back
              </Button>
              <img
                src={InfoImg} // Replace with your image path or URL
                alt="Empty state illustration"
                style={{ width: "100%", maxWidth: "1000px", height: "50vh" }}
              />
              <p className="mt-3" style={{ display: "inline" }}>
                No saved task information is available.
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => setIsPopupOpen(true)}
                  style={{
                    display: "inline",
                    borderRadius: "4px", // Keeps the button rectangular
                    maxWidth: "80px", // Adjusts the height and length of the button
                    maxHeight: "25px",

                    fontSize: "12px", // Optionally reduce font size if needed
                  }}
                >
                  Add
                </button>
              </p>
            </div>
          ) : (
            <div>
              <div className="d-flex">
                <Button className="btn btn-btn-primary" onClick={() => navigate('/calendar')}
                  style={{ marginRight: '1vw' }}>
                  Back
                </Button>
                <button className="btn btn-primary mb-3" onClick={() => setIsPopupOpen(true)}>
                  Add
                </button>
                <div className="text-right " style={{marginLeft:'1rem'}}>
                  <p>
                    <strong>Total Duration:</strong>{" "}
                    {(() => {
                      const totalDuration = fetchedData.reduce((acc, entry) => {
                        const [hours, minutes] = entry.duration.split(":").map(Number);
                        return acc + hours * 60 + minutes;
                      }, 0);
                      const formattedDuration = `${Math.floor(totalDuration / 60)}:${totalDuration % 60 < 10
                          ? `0${totalDuration % 60}`
                          : totalDuration % 60
                        }`;
                      return formattedDuration;
                    })()}{" "}
                  </p>
                </div>
              </div>
              <div className="card-deck">
                {fetchedData.map((entry, index) => (
                  <div key={entry._id} className="card p-3 shadow mb-4">
                    <div className="card-body">
                      {/* Editable projectName */}
                      <h5 className="card-title">
                        <span className="timesheet-date" style={{ fontSize: '1.5rem', color: '#6c757d' }}>
                          {entry.TimesheetDate} {/* Replace this with the actual Timesheetdate */}
                        </span>
                        <br />
                        {isEditing && editData?._id === entry._id ? (
                          <select
                            className="form-control"
                            value={editData?.projectName || ""}
                            onChange={(e) => {
                              handleChange({ target: { name: "projectName", value: e.target.value } });
                              setEditData((prev) => ({
                                ...prev,
                                taskName: "",
                                measurements: [],
                                additionalFields: [],
                              }));
                            }}
                          >
                            <option value="">Select a project</option>
                            {projects.map((project) => (
                              <option key={project._id} value={project.projectName}>
                                {project.projectName}
                              </option>
                            ))}
                          </select>
                        ) : (
                          entry.projectName
                        )}

                      </h5>


                      <h6 className="card-title">
                        Task:{" "}
                        {isEditing && editData._id === entry._id ? (
                          <select
                            className="form-control"
                            value={editData.taskName}
                            onChange={(e) => {
                              handleChange({ target: { name: "taskName", value: e.target.value } });
                              // Dynamically load measurements and additional fields for the selected task
                              const selectedTask = tasks.find((task) => task.taskName === e.target.value);
                              setEditData((prev) => ({
                                ...prev,
                                measurements: selectedTask?.measurements || [],
                                additionalFields: selectedTask?.additionalFields || [],
                              }));
                            }}
                            disabled={!editData.projectName}
                          >
                            <option value="">Select a task</option>
                            {tasks
                              .filter((task) => task.projectName === editData.projectName) // Filter tasks by project
                              .map((task) => (
                                <option key={task._id} value={task.taskName}>
                                  {task.taskName}
                                </option>
                              ))}
                          </select>
                        ) : (
                          entry.taskName
                        )}
                      </h6>

                      {/* Editable description */}
                      <p className="card-text">
                        <strong>Description:</strong>{" "}
                        {isEditing && editData._id === entry._id ? (
                          <textarea
                            name="description"
                            value={editData.description}
                            onChange={handleChange}
                            className="form-control"
                          />
                        ) : (
                          entry.description
                        )}
                      </p>

                      {/* Editable start time */}
                      <p className="card-text">
                        <strong>Start Time:</strong>
                        {isEditing && editData._id === entry._id ? (
                          <div className="d-flex">
                            <select
                              value={editData.startTimeHours}
                              onChange={(e) => {
                                handleEditTimeChange("startTimeHours", e.target.value);
                                handleSessionDeselect(); // Deselect session when time is changed
                              }}
                              className="form-control mr-2"
                            >
                              <option value="">HH</option>
                              {Array.from({ length: 24 }, (_, i) => (
                                <option
                                  key={i}
                                  value={i}
                                  disabled={
                                    editData.endTimeHours !== "" &&
                                    i > parseInt(editData.endTimeHours) // Disable hours later than end time
                                  }
                                >
                                  {i < 10 ? `0${i}` : i}:00
                                </option>
                              ))}
                            </select>
                            <select
                              value={editData.startTimeMinutes}
                              onChange={(e) => {
                                handleEditTimeChange("startTimeMinutes", e.target.value);
                                handleSessionDeselect(); // Deselect session when time is changed
                              }}
                              className="form-control"
                            >
                              <option value="">MM</option>
                              {[0, 15, 30, 45].map((minute) => (
                                <option
                                  key={minute}
                                  value={minute}
                                  disabled={
                                    editData.startTimeHours === editData.endTimeHours &&
                                    editData.endTimeMinutes !== "" &&
                                    minute >= parseInt(editData.endTimeMinutes) // Disable minutes later than end time
                                  }
                                >
                                  {minute < 10 ? `0${minute}` : minute}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          entry.startTime
                        )}
                      </p>

                      <p className="card-text">
                        <strong>End Time:</strong>
                        {isEditing && editData._id === entry._id ? (
                          <div className="d-flex">
                            <select
                              value={editData.endTimeHours}
                              onChange={(e) => {
                                handleEditTimeChange("endTimeHours", e.target.value);
                                handleSessionDeselect(); // Deselect session when time is changed
                              }}
                              className="form-control mr-2"
                            >
                              <option value="">HH</option>
                              {Array.from({ length: 24 }, (_, i) => (
                                <option
                                  key={i}
                                  value={i}
                                  disabled={
                                    editData.startTimeHours !== "" &&
                                    i < parseInt(editData.startTimeHours) // Disable hours earlier than start time
                                  }
                                >
                                  {i < 10 ? `0${i}` : i}:00
                                </option>
                              ))}
                            </select>
                            <select
                              value={editData.endTimeMinutes}
                              onChange={(e) => {
                                handleEditTimeChange("endTimeMinutes", e.target.value);
                                handleSessionDeselect(); // Deselect session when time is changed
                              }}
                              className="form-control"
                            >
                              <option value="">MM</option>
                              {[0, 15, 30, 45].map((minute) => (
                                <option
                                  key={minute}
                                  value={minute}
                                  disabled={
                                    editData.endTimeHours === editData.startTimeHours &&
                                    editData.startTimeMinutes !== "" &&
                                    minute <= parseInt(editData.startTimeMinutes) // Disable minutes earlier than start time
                                  }
                                >
                                  {minute < 10 ? `0${minute}` : minute}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          entry.endTime
                        )}
                        <br />

                        <strong>Duration:</strong>{" "}
                        {isEditing && editData._id === entry._id ? (
                          <input
                            type="text"
                            name="duration"
                            value={editData.duration}
                            className="form-control"
                          />
                        ) : (
                          entry.duration + " hours" // Display the duration as it is
                        )}
                      </p>





                      {/* Editable measurements */}
                      {isEditing && editData._id === entry._id ? (
                        <p className="card-text">
                          <strong>Measurements:</strong>
                          {Array.isArray(editData.measurements) && editData.measurements.length > 0 ? (
                            editData.measurements.map((measurement, index) => (
                              <div key={index} className="mb-2">
                                <strong>{measurement?.measurementName}</strong>
                                <br />
                                <input
                                  type="text"
                                  name="value"
                                  value={measurement?.value || ""}
                                  onChange={handleMeasurementEdit(index)}
                                  className="form-control mb-1"
                                  placeholder="Measurement Value"
                                />
                              </div>
                            ))
                          ) : (
                            <p>No measurements available</p>
                          )}
                        </p>
                      ) : (
                        entry.measurements && entry.measurements.length > 0 ? (
                          <p className="card-text">
                            <strong>Measurements:</strong>
                            {entry.measurements.map((measurement, index) => (
                              <span key={index}>
                                <strong>{measurement?.measurementName}</strong>: {measurement?.value || "N/A"}
                                <br />
                              </span>
                            ))}
                          </p>
                        ) : (
                          <p>No measurements available</p>
                        )
                      )}

                      {/* Editable additional fields */}
                      {isEditing && editData._id === entry._id ? (
                        <p className="card-text">
                          <strong>Custom Fields:</strong>
                          {editData.additionalFields.length > 0 ? (
                            editData.additionalFields.map((field, index) => (
                              <div key={field._id} className="mb-2">
                                <strong>{field.additionalFieldName}</strong>
                                <br />
                                <input
                                  type="text"
                                  name="newValue"
                                  value={field.newValue || ""}
                                  onChange={handleAdditionalFieldEdit(index)}
                                  className="form-control mb-1"
                                  placeholder="Field Value"
                                />
                              </div>
                            ))
                          ) : (
                            <p>No Custom fields available</p>
                          )}
                        </p>
                      ) : (
                        entry.additionalFields.length > 0 ? (
                          <p className="card-text">
                            <strong>Custom Fields:</strong>
                            <br />
                            {entry.additionalFields.map((field) => (
                              <span key={field._id}>
                                <strong>{field.additionalFieldName}</strong>: {field.newValue || "N/A"}
                                <br />
                              </span>
                            ))}
                          </p>
                        ) : (
                          <p>No Custom fields available</p>
                        )
                      )}


                      {/* Edit and Save buttons */}
                      {isEditing && editData._id === entry._id ? (
                        <>
                          <button className="btn btn-success me-2" onClick={handleSaveEdit}>
                            Save
                          </button>
                          <button className="btn btn-secondary" onClick={() => setIsEditing(false)}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button className="btn btn-warning me-2" onClick={() => handleEdit(entry)}>
                          Edit
                        </button>
                      )}

                      {/* Delete button */}
                      <button className="btn btn-danger" onClick={() => handleDelete(entry._id)}>
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          )}

          {isPopupOpen && (
            <div className="popup">
              <div className="popup-content">
                <h5>Add New Task</h5>
                <form>
                  {/* Project Dropdown */}
                  <div className="form-group">
                    <label>Project</label>
                    <select
                      className="form-control"
                      value={formData.selectedProject}
                      onChange={(e) =>
                        handleInputChange("selectedProject", e.target.value)
                      }
                    >
                      <option value="">Select a project</option>
                      {projects.map((project) => (
                        <option key={project._id} value={project.projectName}>
                          {project.projectName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Task Dropdown */}
                  {/* Task Dropdown */}
                  <div className="form-group" >
                    <label style={{ flexShrink: 0 }}>Task</label>
                    <div className="form-group1" style={{ display: "flex", alignItems: "center" }}>

                      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                        <select
                          className="form-control"
                          style={{ flex: 1, marginRight: "8px" }} // Adjust dropdown size and spacing
                          value={formData.selectedTask}
                          onChange={(e) => handleInputChange("selectedTask", e.target.value)}
                          disabled={!formData.selectedProject}
                        >
                          <option value="">Select a task</option>
                          {tasks.map((task) => (
                            <option key={task._id} value={task.taskName}>
                              {task.taskName}
                            </option>
                          ))}
                        </select>

                        {/* Add 'i' button near the task dropdown */}
                        {formData.selectedTask && (
                          <span
                            className="info-button"
                            style={{
                              fontSize: "1.5rem", // Increase size of the button
                              cursor: "pointer",
                              color: "#007bff",
                            }}
                            title={
                              tasks.find((task) => task.taskName === formData.selectedTask)?.taskDesc || "No description available"
                            }
                          >
                            ⓘ
                          </span>
                        )}
                      </div>
                    </div>
                  </div>


                  <div className="form-group d-flex align-items-center">
                    <label
                      className="d-flex align-items-center mr-3"
                      style={{ marginRight: "0.5vw" }}
                    >
                      <HiOutlineBuildingOffice2 size={20} className="mr-1" />
                      <input
                        type="radio"
                        checked={formData.workType === "On-Site"}
                        onChange={() => handleWorkTypeChange("On-Site")}
                        style={{ marginRight: "5px" }}
                      />
                      On-Site
                    </label>

                    <label className="d-flex align-items-center">
                      <HiOutlineHome size={20} />
                      <input
                        type="radio"
                        checked={formData.workType === "Remote"}
                        onChange={() => handleWorkTypeChange("Remote")}
                        style={{ marginRight: "5px" }}
                      />
                      Remote
                    </label>
                  </div>

                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={formData.description}
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    ></textarea>
                  </div>

                  <div className="form-group d-flex">
                    {/* Start Time */}
                    <div className="mr-3" style={{ width: "33%" }}>
                      <label>Start Time</label>
                      <select
                        value={formData.startTimeHours}
                        onChange={(e) => {
                          handleTimeChange("startTimeHours", e.target.value);
                          handleSessionDeselect(); // Deselect session when time is changed
                        }}
                        className="form-control"
                        required
                      >
                        <option value="">HH</option> {/* Placeholder for hours */}
                        {Array.from({ length: 24 }, (_, i) => (
                          <option
                            key={i}
                            value={i}
                            disabled={formData.endTimeHours !== "" && i > formData.endTimeHours}
                          >
                            {i < 10 ? `0${i}` : i}:00
                          </option>
                        ))}
                      </select>
                      <select
                        value={formData.startTimeMinutes}
                        onChange={(e) => {
                          handleTimeChange("startTimeMinutes", e.target.value);
                          handleSessionDeselect(); // Deselect session when time is changed
                        }}
                        className="form-control mt-2"
                      >
                        <option value="00">MM</option> {/* Placeholder for minutes */}
                        {[0, 15, 30, 45].map((minute) => (
                          <option
                            key={minute}
                            value={minute}
                            disabled={
                              formData.endTimeHours !== "" &&
                              formData.startTimeHours === formData.endTimeHours &&
                              minute >= formData.endTimeMinutes
                            }
                          >
                            {minute < 10 ? `0${minute}` : minute}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* End Time */}
                    <div className="mr-3" style={{ width: "33%" }}>
                      <label>End Time</label>
                      <select
                        value={formData.endTimeHours}
                        onChange={(e) => {
                          handleTimeChange("endTimeHours", e.target.value);
                          handleSessionDeselect(); // Deselect session when time is changed
                        }}
                        className="form-control"
                      >
                        <option value="">HH</option> {/* Placeholder for hours */}
                        {Array.from({ length: 24 }, (_, i) => (
                          <option
                            key={i}
                            value={i}
                            disabled={formData.startTimeHours !== "" && i < formData.startTimeHours}
                          >
                            {i < 10 ? `0${i}` : i}:00
                          </option>
                        ))}
                      </select>
                      <select
                        value={formData.endTimeMinutes}
                        onChange={(e) => {
                          handleTimeChange("endTimeMinutes", e.target.value);
                          handleSessionDeselect(); // Deselect session when time is changed
                        }}
                        className="form-control mt-2"
                      >
                        <option value="00">MM</option> {/* Placeholder for minutes */}
                        {[0, 15, 30, 45].map((minute) => (
                          <option
                            key={minute}
                            value={minute}
                            disabled={
                              formData.startTimeHours !== "" &&
                              formData.startTimeHours === formData.endTimeHours &&
                              minute <= formData.startTimeMinutes
                            }
                          >
                            {minute < 10 ? `0${minute}` : minute}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Duration Field */}
                    <div style={{ width: "33%" }}>
                      <label>Duration</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.duration}
                        readOnly
                      />
                    </div>
                  </div>


                  <div className="form-group">
                    <label>Session</label>
                    <div className="d-flex">
                      <div className="mr-3" style={{ marginRight: "0.5vw" }}>
                        <input
                          type="radio"
                          id="fullDay"
                          name="session"
                          checked={formData.session === "Full Day"}
                          onChange={() => handleSessionChange("Full Day")}
                        />
                        <label htmlFor="fullDay">Full Day</label>
                      </div>
                      <div className="mr-3" style={{ marginRight: "0.5vw" }}>
                        <input
                          type="radio"
                          id="firstHalf"
                          name="session"
                          checked={formData.session === "First Half"}
                          onChange={() => handleSessionChange("First Half")}
                        />
                        <label htmlFor="firstHalf">First Half</label>
                      </div>
                      <div className="mr-3" style={{ marginRight: "0.5vw" }}>
                        <input
                          type="radio"
                          id="secondHalf"
                          name="session"
                          checked={formData.session === "Second Half"}
                          onChange={() => handleSessionChange("Second Half")}
                        />
                        <label htmlFor="secondHalf">Second Half</label>
                      </div>
                    </div>
                  </div>

                  {/* Render measurement inputs dynamically with a heading */}
                  {formData.selectedTask && measurements.length > 0 && (
                    measurements.map((measurement, index) => (
                      <div className="form-group" key={index}>
                        <label>Measurement {index + 1}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formData.measurements[index]?.value || ""}
                          onChange={(e) => handleMeasurementChange(index, e.target.value)}
                          placeholder={measurement.measurementName}
                          required
                        />
                      </div>
                    ))
                  )}

                  {/* Render additional fields dynamically */}
                  {formData.selectedTask && additionalFields.length > 0 && (
                    <div>
                      {additionalFields.map((field, index) => (
                        <div className="form-group" key={index}>
                          <label>Custom Fields</label>
                          <input
                            type="text"
                            className="form-control"
                            value={formData.additionalFields[index]?.newValue || ""} // Display only the newValue
                            onChange={(e) =>
                              handleAdditionalFieldChange(index, e.target.value)
                            }
                            placeholder={`${field.additionalFieldName}${field.isRequired ? ' *' : ''}`} // Add an asterisk in the placeholder if required
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary ml-2"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          )}


        </div>
      </div>
      <ToastContainer /> {/* Toast container for notifications */}
    </div>
  );
};

export default TaskForm;