// index.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import './styles.module.css'; // Custom styles for the specific UI adjustments
import uri from '../../config';

const ClientManager = () => {
    const [clients, setClients] = useState([]);
    const [show, setShow] = useState(false);
    const [newClient, setNewClient] = useState({ name: '', email: '', address: '', note: '', currency: 'INR' });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchClients = async () => {
        const response = await axios.get(`${uri}/api/getclients`);
        setClients(response.data);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewClient((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios.post(`${uri}/api/clients`, newClient);
        fetchClients();
        handleClose();
    };

    useEffect(() => {
        fetchClients();
    }, []);

    return (
        <div className="row">
                <div className="col-md-3">
                    
                </div>
        <div className="container mt-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Clients</h2>
                <Button variant="primary" onClick={handleShow}>
                    Add new client
                </Button>
            </div>

            <Table striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Currency</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map((client) => (
                        <tr key={client._id}>
                            <td>{client.name}</td>
                            <td>{client.address}</td>
                            <td>{client.currency}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" value={newClient.name} onChange={handleChange} required />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" value={newClient.email} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name="address" value={newClient.address} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formNote">
                            <Form.Label>Note</Form.Label>
                            <Form.Control type="text" name="note" value={newClient.note} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formCurrency">
                            <Form.Label>Currency</Form.Label>
                            <Form.Control as="select" name="currency" value={newClient.currency} onChange={handleChange}>
                                <option value="INR">INR</option>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
        </div>
    );
};

export default ClientManager;
