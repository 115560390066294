import React, { useRef, useState, useEffect } from 'react';

import uri from '../../config';
import Avatar from 'react-avatar';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import Header from "../Header/index";
import ChangePassword from '../ChangePassword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../sidebar';
import './profile.css'; // Assuming you have a CSS file for Profile component

const Profile = () => {
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || 'First Name');
  const [lastName, setLastName] = useState(localStorage.getItem('lastName') || 'Last Name');
  const [organization, setOrganization] = useState(localStorage.getItem('organization') || 'Organization');
  const [name, setName] = useState(localStorage.getItem('userName') || 'Profile');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [editEmail, setEditEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  useEffect(() => {
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedOrganization = localStorage.getItem('organization');
    const storedName = localStorage.getItem('userName');
    const storedEmail = localStorage.getItem('email');

    if (storedFirstName) setFirstName(storedFirstName);
    if (storedLastName) setLastName(storedLastName);
    if (storedOrganization) setOrganization(storedOrganization);
    if (storedName) setName(storedName);
    if (storedEmail) setEmail(storedEmail);

    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(`${uri}/api/profile/getimage`, {
          params: { email: storedEmail },
        });

        if (response.data.profileImage) {
          setImage(response.data.profileImage);
        } else {
          setImage(null);
        }
      } catch (error) {
        console.error('Error fetching profile image', error);
      }
    };

    if (storedEmail) {
      fetchProfileImage();
    }
  }, []);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    formData.append('email', email);

    try {
      await axios.post(`${uri}/api/profile/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error uploading image', error);
    }
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    localStorage.setItem('userName', newName);
  };

  const handleEmailChange = (event) => {
    setNewEmail(event.target.value);
  };

  const handleEmailUpdate = async () => {
    try {
      await axios.put(`${uri}/api/updateemail/email`, { oldEmail: email, newEmail });
      setEmail(newEmail);
      localStorage.setItem('email', newEmail);
      setEditEmail(false);
    } catch (error) {
      console.error('Error updating email', error);
    }
  };

  const handleRemoveImage = async () => {
    setImage(null);
    try {
      await axios.put(`${uri}/api/profile/image`, { email });
    } catch (error) {
      console.error('Error removing image', error);
    }
  };

  const handleClosePasswordModal = () => setShowPasswordModal(false);
  const handleShowPasswordModal = () => setShowPasswordModal(true);

  return (
    <div className="row2">
      <div className="col-md-2">
        <Header />
      </div>
      <div style={{ display: "flex", width: "auto", height: "100vh" }}>
        <Sidebar />
        <div className="profile-wrapper" style={{marginTop:"7vw"}}>
          <div className="profile container-fluid mt-5">
            <div className="row">
              <div className="col-md-3">
                <div className="profile-left">
                  <div className="profile-image">
                    {image ? (
                      <img src={image} style={{width:'130px', height:'130px'}} alt="Profile" className="profile-avatar" />
                    ) : (
                      <Avatar name={name} size="100" round={true} />
                    )}
                    <Button className="btn1" onClick={handleImageClick}>
                      <FontAwesomeIcon icon={faUpload} />
                    </Button>
                    <input
                      type="file"
                      ref={inputRef}
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                    />
                    <Button className="btn2" onClick={handleRemoveImage}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                  <div className="profile-info">
                    <br></br>
                    <p>User Name: {name} </p>
                    <p>Organization: {organization}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="profile-right">
                  <h3>Personal Information</h3>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      placeholder="Enter your name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <div className="email-edit">
                      {editEmail ? (
                        <input
                          type="text"
                          value={newEmail}
                          onChange={handleEmailChange}
                          placeholder="Enter your new email"
                          className="form-control"
                        />
                      ) : (
                        <input
                          type="text"
                          value={email}
                          readOnly
                          className="form-control"
                        />
                      )}
                      {editEmail ? (
                        <Button onClick={handleEmailUpdate}>Update</Button>
                      ) : (
                        <Button onClick={() => setEditEmail(true)}>Change Email</Button>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Button onClick={handleShowPasswordModal}>Change Password</Button>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
              <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ChangePassword />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePasswordModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
