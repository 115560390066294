import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav, Button, Row, Col, Card, ButtonGroup } from 'react-bootstrap';
import { FaTasks, FaChartLine, FaFilePdf } from 'react-icons/fa';
import SpurTrack from "./Spurtrack.png";
import backgroundImage from "./homepagebackground.png";

const HomePage = () => {
  return (
    <div>
      {/* Header */}
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">SpurTrack</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="https://spurqlabs.com/about/">About</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
              <Nav.Link href="/login">Sign In</Nav.Link>
              <Button variant="outline-light" href="/signup-email-verify" className="ms-2">Get Started</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      {/* Hero Section */}
<div
  className="text-white text-center py-5"
  style={{
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '600px',
  }}
>
</div>

{/* Welcome Section */}
<Container className="mt-4">
  <h1 style={{ fontSize: '3rem', fontWeight: 'bold' }}>Welcome to SpurTrack</h1>
  <p className="lead" style={{ fontSize: '1.5rem' }}>
    SpurTrack is a project management and timesheet tool that helps teams log tasks, track time, and generate AI-powered reports like WSRs. It offers seamless integration with analytics and supports export to Excel and PDF for streamlined productivity.
  </p>
  <ButtonGroup className="mt-4">
    <Button variant="dark" href="/signup-email-verify" size="lg" className="me-3">Get Started</Button>
  </ButtonGroup>
</Container>


      {/* Overview Section */}
      <Container className="mt-5">
        <Row className="text-center">
          <Col md={4} xs={12}>
            <Card className="mb-4 shadow-lg h-100">
              <Card.Body className="d-flex flex-column">
                <FaTasks size={50} className="mb-3 text-primary" />
                <Card.Title>Task & Time Tracking</Card.Title>
                <Card.Text>
                  SpurTrack helps teams easily log tasks and track work hours per project, ensuring transparency and accountability across all activities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} xs={12}>
            <Card className="mb-4 shadow-lg h-100">
              <Card.Body className="d-flex flex-column">
                <FaChartLine size={50} className="mb-3 text-primary" />
                <Card.Title>Advanced Analytics</Card.Title>
                <Card.Text>
                  SpurTrack provides detailed insights into time allocation, project progress, and employee productivity with exportable reports in Excel and PDF formats.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} xs={12}>
            <Card className="mb-4 shadow-lg h-100">
              <Card.Body className="d-flex flex-column">
                <FaFilePdf size={50} className="mb-3 text-primary" />
                <Card.Title>Comprehensive Reports</Card.Title>
                <Card.Text>
                  Generate custom, detailed reports including timesheets, task summaries, and WSRs, exportable to PDF or Excel for easy sharing and tracking.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Call-to-Action */}
        <div className="text-center mt-5">
          <h2>Ready to Streamline Your Projects?</h2>
          <p className="lead">
            Start using SpurTrack today to manage time, tasks, and reports efficiently with AI-powered insights.
          </p>
          <ButtonGroup>
            <Button variant="dark" href="/signup-email-verify" size="lg" className="me-3">Get Started</Button>
            
          </ButtonGroup>
        </div>
      </Container>

      {/* Footer */}
      <footer className="bg-dark text-white mt-5 p-4">
        <Container>
          <Row className="align-items-center">
            <Col md={4} className="text-md-start text-center mb-2 mb-md-0">
              <img src={SpurTrack} alt="Spur Track Logo" style={{ width: '120px' }} />
            </Col>
            <Col md={4} className="d-flex justify-content-center">
              <Nav className="flex-row">
                <Nav.Link href="https://spurqlabs.com/about/" className="text-white px-2">About</Nav.Link>
                <Nav.Link href="/contact" className="text-white px-2">Contact Us</Nav.Link>
                <Nav.Link href="/login" className="text-white px-2">Sign In</Nav.Link>
              </Nav>
            </Col>
            <Col md={4} className="text-md-end text-center">
              <p>&copy; {new Date().getFullYear()} SpurTrack. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default HomePage;
