// Blogs/index.jsx

import React from 'react';
import styles from './styles.module.css';

const Blogs = () => {
  return (
    <div id='Main-Container' className={styles.blogsContainer}>
      <h1>Blogs</h1>
      <p>This is the Blogs page content.</p>
    </div>
  );
};

export default Blogs;
