import React, { useState, useEffect } from "react";
import "./team.css";
import { Modal, Button, Form, InputGroup, Table } from 'react-bootstrap'; // Removed Toast
import { toast, ToastContainer } from 'react-toastify'; // Keep toastify for managing toasts
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Sidebar from "../sidebar";
import Header from "../Header/index";
import axios from "axios";
import uri from "../../config";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const Team = () => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;
  const role = localStorage.getItem("role");
  // State to track role changes
  const [roleChanges, setRoleChanges] = useState({}); // { email: "newRole" }
  const [isAdding, setIsAdding] = useState(false);
  // State for modal and toast
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("Member"); // Default role
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success"); // success or error
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsSuperAdmin(role === 'SuperAdmin');
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const organization = localStorage.getItem('organization');
        if (!organization) {
          console.error("Organization is not available.");
          return;
        }

        const response = await axios.get(`${uri}/api/findusers`, {
          params: { organization }
        });

        setTeamMembers(response.data);
      } catch (error) {
        console.error("Error fetching team members:", error);
      }
    };

    fetchTeamMembers();
  }, []);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedTeam = [...teamMembers].sort((a, b) => {
    if (!sortConfig.key) return 0;
    const order = sortConfig.direction === "ascending" ? 1 : -1;

    if (sortConfig.key === "name") {
      const nameA = `${a.firstName || ""} ${a.lastName || ""}`.toLowerCase();
      const nameB = `${b.firstName || ""} ${b.lastName || ""}`.toLowerCase();
      if (nameA < nameB) return -1 * order;
      if (nameA > nameB) return 1 * order;
      return 0;
    } else {
      if (a[sortConfig.key] < b[sortConfig.key]) return -1 * order;
      if (a[sortConfig.key] > b[sortConfig.key]) return 1 * order;
      return 0;
    }
  });

  const filteredTeam = sortedTeam
    .filter((member) => {
      if (filter === "Active") return member.active === true; // Corrected to use boolean comparison
      if (filter === "Inactive") return member.active === false; // Corrected to use boolean comparison
      return true;
    })
    .filter((member) => {
      const searchLower = search.toLowerCase();
      const name = `${member.firstName || ""} ${member.lastName || ""}`.toLowerCase();
      const email = member.email ? member.email.toLowerCase() : "";
      const role = member.role ? member.role.toLowerCase() : "";

      return (
        name.includes(searchLower) ||
        email.includes(searchLower) ||
        role.includes(searchLower)
      );
    });


  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredTeam.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredTeam.length / entriesPerPage);



  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <FaSortUp />
      ) : (
        <FaSortDown />
      );
    }
    return null;
  };

  const handleSidebarToggle = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle role change
  const handleRoleChange = (email, newRole) => {
    setRoleChanges((prevChanges) => ({
      ...prevChanges,
      [email]: newRole,
    }));
  };

  // Function to save role change
  const handleSaveRoleChange = async (email) => {
    const newRole = roleChanges[email];

    try {
      const response = await axios.put(`${uri}/api/useractions/update-role`, {
        email,
        newRole,
      });

      if (response.data.message) {
        toast.success(`Role updated successfully for ${email}!`);

        setTeamMembers((prevMembers) =>
          prevMembers.map((m) =>
            m.email === email ? { ...m, role: newRole } : m
          )
        );
      } else {
        toast.error("Failed to update role.");
      }
    } catch (error) {
      toast.error("Error while updating role.");
    }
  };

  // Add a new member
  const handleAddMember = async () => {
    try {
      setIsAdding(true); // Start the loading state
  
      const adminEmail = localStorage.getItem('email');
      const organization = localStorage.getItem('organization');
  
      const response = await axios.post(`${uri}/api/inviteuser`, {
        invitedEmail: email,
        selectedRole,
        adminEmail,
        organization,
      });
  
      if (response.status === 200) {
        toast.success('Invitation sent successfully!');
        setShowModal(false);
        setEmail('');
        setSelectedRole('Member');
      }
    } catch (error) {
      toast.error('Error Sending Invite');
      console.error('Error sending invitation:', error.response?.data || error.message);
    } finally {
      setIsAdding(false); // End the loading state, regardless of success or error
    }
  };
  
  const toggleStatus = async (member) => {
    try {
      const newStatus = !member.active; // Toggle the active status
      const response = await axios.put(`${uri}/api/useractions/update-status`, {
        email: member.email,
        status: newStatus ? "Active" : "Inactive", // Sending status as "Active" or "Inactive"
      });

      if (response.data.message) {
        setToastMessage(response.data.message);
        setToastType("success");

        // Update the user's active status in the local state
        setTeamMembers((prevMembers) =>
          prevMembers.map((m) =>
            m.email === member.email ? { ...m, active: newStatus } : m
          )
        );
      } else {
        setToastMessage("Failed to update status.");
        setToastType("error");
      }
    } catch (error) {
      setToastMessage("Error while updating status.");
      setToastType("error");
    }

    setShowToast(true);
  };


  return (
    <div>
      <ToastContainer />
      {/* Sidebar and Content */}
      

        <div className="team-container">
          <div className="team-header">
            <InputGroup className="mb-3 search-bar">
              <Form.Control
                placeholder="Search by name or email..."
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>

            <Form.Select
              className="filter-dropdown"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Form.Select>
            {role === "SuperAdmin" && (
              <Button
                className="userbtn"
                variant="secondary"
                onClick={() => setShowModal(true)}
              >
                Add User
              </Button>
            )}
          </div>

          <Table hover>
            <thead>
              <tr>
                <th onClick={() => requestSort("name")} style={{ cursor: "pointer" }}>
                  Name {getSortIcon("name")}
                </th>
                <th onClick={() => requestSort("email")} style={{ cursor: "pointer" }}>
                  Email {getSortIcon("email")}
                </th>
                <th>Role</th>
                <th>Status</th>
                {isSuperAdmin && (
                <th>Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((member) => (
                <tr key={member.email}>
                  <td>{`${member.firstName} ${member.lastName}`}</td>
                  <td>{member.email}</td>
                  <td>
                    <div>
                      {role === "SuperAdmin" ? (
                        <>
                          <Form.Check
                            inline
                            label="Admin"
                            type="radio"
                            checked={roleChanges[member.email] ? roleChanges[member.email] === "Admin" : member.role === "Admin"}
                            onChange={() => handleRoleChange(member.email, "Admin")}
                          />
                          <Form.Check
                            inline
                            label="Member"
                            type="radio"
                            checked={roleChanges[member.email] ? roleChanges[member.email] === "Member" : member.role === "Member"}
                            onChange={() => handleRoleChange(member.email, "Member")}
                          />
                          <Form.Check
                            inline
                            label="SuperAdmin"
                            type="radio"
                            checked={roleChanges[member.email] ? roleChanges[member.email] === "SuperAdmin" : member.role === "SuperAdmin"}
                            onChange={() => handleRoleChange(member.email, "SuperAdmin")}
                          />
                        </>
                      ) : (
                        <span>{member.role}</span>
                      )}
                    </div>
                    {roleChanges[member.email] && roleChanges[member.email] !== member.role && (
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleSaveRoleChange(member.email)}
                        style={{ marginLeft: "10px" }}
                      >
                        Change Role
                      </Button>
                    )}
                  </td>
                  <td>
                    {member.active ? "Active" : "Inactive"}
                  </td>
                  {isSuperAdmin && (
                    <td>
                      {/* Button to activate/deactivate based on current status */}
                      <Button onClick={() => toggleStatus(member)}>
                        {member.active ? 'Deactivate' : 'Activate'}
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>


          {/* Pagination Controls */}
          <div
            className="pagination-controls"
            style={{ textAlign: "right", paddingTop: "10px" }}
          >
            <span
              className="pagination-prev"
              onClick={handlePrevPage}
              style={{ cursor: "pointer", marginRight: "15px" }}
            >
              <BsChevronLeft /> Previous
            </span>
            <span style={{ marginRight: "15px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <span
              className="pagination-next"
              onClick={handleNextPage}
              style={{ cursor: "pointer" }}
            >
              Next <BsChevronRight />
            </span>
          </div>
        </div>
     

      {/* Modal for Adding User */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Form.Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="Member">Member</option>
                <option value="Admin">Admin</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
        <Button
    variant="primary"
    onClick={handleAddMember}
    disabled={isAdding} // Disable button when adding
  >
    {isAdding ? 'Adding...' : 'Add User'} {/* Change button text */}
  </Button>
        </Modal.Footer>
      </Modal>


    </div>
  );
};

export default Team;
