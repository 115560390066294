import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencil, BsTrash, BsCheck, BsX } from "react-icons/bs";
import Select from 'react-select'; // Import react-select
import uri from '../../config';

const OkraPage = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [okraData, setOkraData] = useState({
    objectives: [],
    metrics: [],
    keyResults: [],
    tasks: [],
    measurements: []
  });
  const [editRowId, setEditRowId] = useState(null);
  const [dropdownData, setDropdownData] = useState({
    objectives: [],
    metrics: [],
    keyResults: [],
    tasks: [],
    measurements: []
  });
  const [oldValues, setOldValues] = useState({
    objectives: null,
    metrics: null,
    keyResults: null,
    tasks: null,
    measurements: null,
  });
  

  // Fetch departments and dropdown data on load
  useEffect(() => {
    fetchDepartments();
    fetchDropdownData();
  }, []);

  const fetchDepartments = async () => {
    try {
      const organization = localStorage.getItem('organization');
      const response = await axios.get(`${uri}/api/departments`, { params: { organization } });
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchOkraData = async (departmentName) => {
    try {
      const organization = localStorage.getItem('organization');
      const response = await axios.get(`${uri}/api/okra`, { params: { organization, department: departmentName } });
      setOkraData(response.data);
    } catch (error) {
      console.error("Error fetching OKRA data:", error);
    }
  };

  const fetchDropdownData = async () => {
    try {
      const organization = localStorage.getItem('organization');
      const response = await axios.get(`${uri}/api/okra/getdata`, { params: { organization } });
      setDropdownData(response.data);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  const handleDepartmentChange = (e) => {
    const departmentName = e.target.value;
    setSelectedDepartment(departmentName);
    fetchOkraData(departmentName);
  };

  const handleEditClick = (id) => {
    // Find the data related to the clicked row ID
    const selectedRow = okraData.metrics.find((metric) => metric._id === id);
  
    if (selectedRow) {
      // Store the original values of each field
      setOldValues({
        objectives: okraData.objectives.find(obj => obj._id === okraData.objectives[selectedRow.index]?._id) || null,
        metrics: selectedRow, // the metric itself
        keyResults: okraData.keyResults.find(kr => kr._id === okraData.keyResults[selectedRow.index]?._id) || null,
        tasks: okraData.tasks.find(task => task._id === okraData.tasks[selectedRow.index]?._id) || null,
        measurements: okraData.measurements.find(measure => measure._id === okraData.measurements[selectedRow.index]?._id) || null,
      });
    }
  
    // Set the row to edit mode
    setEditRowId(id);
  };
  
  const handleSaveClick = async (id) => {
    try {
      // Find the updated row
      const updatedRow = okraData.metrics.find((metric) => metric._id === id);
  
      // Prepare data for the server, including old and new values
      await axios.put(`${uri}/api/okra/metric/${id}`, {
        updatedRow,
        oldValues, // Send the tracked old values
        department: selectedDepartment, // The current department
      });
  
      setEditRowId(null);
      setOldValues({ objectives: null, metrics: null, keyResults: null, tasks: null, measurements: null }); // Reset old values
      fetchOkraData(selectedDepartment); // Refresh data
    } catch (error) {
      console.error("Error updating OKRA data:", error);
    }
  };
  
  

  const handleDropdownChange = (field, selectedOption, index) => {
    const updatedOkraData = { ...okraData };
    const selectedItem = dropdownData[field].find(item => item._id === selectedOption.value);
  
    if (selectedItem) {
      // Update the specific field with the new selection
      updatedOkraData[field][index] = selectedItem;
      setOkraData(updatedOkraData);
    }
  };
  

  const handleDeleteClick = async (id) => {
    try {
      const department = selectedDepartment;
      const organization = localStorage.getItem("organization");

      const entityTypes = [
        "objective",
        "metric",
        "keyResult",
        "task",
        "measurement",
      ];

      for (const type of entityTypes) {
        await axios.delete(`${uri}/api/okra/${type}`, {
          params: { department, organization },
        });
      }

      fetchOkraData(selectedDepartment);
    } catch (error) {
      console.error("Error deleting OKRA data:", error);
    }
  };

  return (
    <div className="container">
      <h2>OKRA's</h2>
      <div className="form-group">
        <label htmlFor="departmentSelect">Select Department</label>
        <select
          id="departmentSelect"
          className="form-control"
          value={selectedDepartment}
          onChange={handleDepartmentChange}
        >
          <option value="">Select a department</option>
          {departments.map((dept) => (
            <option key={dept._id} value={dept.name}>
              {dept.name}
            </option>
          ))}
        </select>
      </div>

      {selectedDepartment && (
        <div className="mt-4">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Objectives</th>
                <th>Metrics</th>
                <th>Key Results</th>
                <th>Tasks</th>
                <th>Measurements</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {okraData.metrics.length > 0 ? (
                okraData.metrics.map((metric, index) => (
                  <tr key={metric._id}>
                    {/* Objectives */}
                    <td>
                      {editRowId === metric._id ? (
                        <Select
                          options={dropdownData.objectives.map(item => ({
                            value: item._id,
                            label: item.objectiveName
                          }))}
                          value={{
                            value: okraData.objectives[index]?._id || "",
                            label: okraData.objectives[index]?.objectiveName || "Select Objective"
                          }}
                          onChange={(selectedOption) => handleDropdownChange("objectives", selectedOption, index)}
                        />
                      ) : (
                        okraData.objectives[index]?.objectiveName || "No data"
                      )}
                    </td>

                    {/* Metrics */}
                    <td>
                      {editRowId === metric._id ? (
                        <Select
                          options={dropdownData.metrics.map(item => ({
                            value: item._id,
                            label: item.metricsName
                          }))}
                          value={{
                            value: metric._id,
                            label: metric.metricsName || "Select Metric"
                          }}
                          onChange={(selectedOption) => handleDropdownChange("metrics", selectedOption, index)}
                        />
                      ) : (
                        metric.metricsName || "No data"
                      )}
                    </td>

                    {/* Key Results */}
                    <td>
                      {editRowId === metric._id ? (
                        <Select
                          
                          options={dropdownData.keyResults.map(item => ({
                            value: item._id,
                            label: item.keyResultName
                          }))}
                          value={{
                            value: okraData.keyResults[index]?._id || "",
                            label: okraData.keyResults[index]?.keyResultName || "Select Key Result"
                          }}
                          onChange={(selectedOption) => handleDropdownChange("keyResults", selectedOption, index)}
                        />
                      ) : (
                        okraData.keyResults[index]?.keyResultName || "No data"
                      )}
                    </td>

                    {/* Tasks */}
                    <td>
                      {editRowId === metric._id ? (
                        <Select
                          options={dropdownData.tasks.map(item => ({
                            value: item._id,
                            label: item.taskName
                          }))}
                          value={{
                            value: okraData.tasks[index]?._id || "",
                            label: okraData.tasks[index]?.taskName || "Select Task"
                          }}
                          onChange={(selectedOption) => handleDropdownChange("tasks", selectedOption, index)}
                        />
                      ) : (
                        okraData.tasks[index]?.taskName || "No data"
                      )}
                    </td>

                    {/* Measurements */}
                    <td>
                      {editRowId === metric._id ? (
                        <Select
                          options={dropdownData.measurements.map(item => ({
                            value: item._id,
                            label: item.measurementName
                          }))}
                          value={{
                            value: okraData.measurements[index]?._id || "",
                            label: okraData.measurements[index]?.measurementName || "Select Measurement"
                          }}
                          onChange={(selectedOption) => handleDropdownChange("measurements", selectedOption, index)}
                        />
                      ) : (
                        okraData.measurements[index]?.measurementName || "No data"
                      )}
                    </td>

                    {/* Actions */}
                    <td>
                      {editRowId === metric._id ? (
                        <>
                          <button
                            onClick={() => handleSaveClick(metric._id)}
                            className="btn btn-sm btn-success mr-2"
                          >
                            <BsCheck />
                          </button>
                          <button
                            onClick={() => setEditRowId(null)}
                            className="btn btn-sm btn-secondary"
                          >
                            <BsX />
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEditClick(metric._id)}
                            className="btn btn-sm btn-primary mr-2"
                          >
                            <BsPencil />
                          </button>
                          <button
                            onClick={() => handleDeleteClick(metric._id)}
                            className="btn btn-sm btn-danger"
                          >
                            <BsTrash />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OkraPage;
