import React, { useState } from "react";
import './orgdashboard.css';
import comingSoonImage from './underdevelopment.png'; // Update this path to your image file location
import Sidebar from "../sidebar";
import Header from "../Header/index";

const Orgdashboard = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <Sidebar
          isExpanded={isSidebarExpanded}
          setIsExpanded={setIsSidebarExpanded}
        />
        <div
          className="content-container"
          style={{
            flexGrow: 1,
            transition: "all 0.3s ease",
            marginLeft: isSidebarExpanded ? "250px" : "0",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f4f7fa",
          }}
        >
          <div className="coming-soon-container"style={{height:"60vh"}}>
            <img src={comingSoonImage} alt="Page Under Construction" className="coming-soon-image" style={{height:"55vh"}} />
            <h1>Coming Soon</h1>
            <p>We're working hard to bring this feature to you. Stay tuned!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orgdashboard;
