import React, { useState } from 'react';
import axios from 'axios';
import uri from '../../config';
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const email = localStorage.getItem('email'); // Retrieve email from localStorage

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`${uri}/api/auth/change-password`, {
                email,
                oldPassword,
                newPassword
            });
            setSuccess(response.data.message);
            setError('');
        } catch (err) {
            setError(err.response.data.message);
            setSuccess('');
        }
    };

    return (
        <div className="change-password">
            <h1>Change Password</h1>
            <Form onSubmit={handlePasswordChange}>
                <Form.Group controlId="oldPassword">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                        type={showOldPassword ? "text" : "password"}
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                    />
                    <Form.Check 
                        type="checkbox" 
                        label="Show Old Password" 
                        checked={showOldPassword}
                        onChange={(e) => setShowOldPassword(e.target.checked)}
                    />
                </Form.Group>
                <Form.Group controlId="newPassword">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <Form.Check 
                        type="checkbox" 
                        label="Show New Password" 
                        checked={showNewPassword}
                        onChange={(e) => setShowNewPassword(e.target.checked)}
                    />
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <Form.Check 
                        type="checkbox" 
                        label="Show Confirm Password" 
                        checked={showConfirmPassword}
                        onChange={(e) => setShowConfirmPassword(e.target.checked)}
                    />
                </Form.Group>
                <br />
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
                <Button variant="primary" type="submit">Update Password</Button>
                <Link to="/forgot-password" className="mb-3 d-block text-decoration-none">
                    Forgot Password?
                </Link>
            </Form>
        </div>
    );
};

export default ChangePassword;
