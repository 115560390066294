import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Toast, ToastContainer } from "react-bootstrap";
import uri from "../../config";

const DepartmentPage = () => {
  const [departments, setDepartments] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editingDepartment, setEditingDepartment] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(""); // success or error
  const [toastMessage, setToastMessage] = useState("");
  const [progress, setProgress] = useState(0); // To manage progress timer

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const organization = localStorage.getItem("organization");
      const response = await axios.get(`${uri}/api/departments`, {
        params: { organization },
      });
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
      showToastMessage("Failed to fetch departments", "danger");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const organization = localStorage.getItem("organization");
      if (editingDepartment) {
        await axios.put(`${uri}/api/departments/${editingDepartment._id}`, { name, description });
        showToastMessage("Department updated successfully", "success");
        setEditingDepartment(null);
      } else {
        await axios.post(`${uri}/api/departments`, { name, description, organization });
        showToastMessage("Department created successfully", "success");
      }
      fetchDepartments();
      setName("");
      setDescription("");
    } catch (error) {
      const errorMsg = error.response?.data?.error || "An error occurred";
      setError(errorMsg);
      showToastMessage("Failed to save the department", "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${uri}/api/departments/${id}`);
      fetchDepartments();
      showToastMessage("Department deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting department:", error);
      showToastMessage("Failed to delete the department", "danger");
    }
  };

  const handleEdit = (department) => {
    setName(department.name);
    setDescription(department.description);
    setEditingDepartment(department);
  };

  // Function to show the custom toast message
  const showToastMessage = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setProgress(100); // Reset the progress when showing the toast

    // Hide the toast after 3 seconds
    setTimeout(() => {
      setShowToast(false);
    }, 3000);

    // Start progress timer for the toast
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 2; // Adjust this for speed
      });
    }, 60);
  };

  return (
    <div className="container">
      {/* Custom Bootstrap Toast Container */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          className="d-inline-block m-1"
          style={{
            minWidth: "300px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(10px)", // For translucency
            WebkitBackdropFilter: "blur(10px)", // Safari support
            borderRadius: "8px",
            overflow: "hidden",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Timer Line */}
          <div
            style={{
              height: "8px", // Increased height
              width: `${progress}%`,
              backgroundColor: toastType === "success" ? "green" : "red",
              transition: "width 30ms linear",
            }}
          />

          {/* Toast Header */}
          <Toast.Header
            closeButton={false}
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <strong className="me-auto" style={{ color: "#000" }}>
              {toastType === "success" ? "Success" : "Error"}
            </strong>
            <button
              type="button"
              onClick={() => setShowToast(false)}
              style={{
                background: "none",
                border: "none",
                color: "#000",
                cursor: "pointer",
                marginLeft: "auto", // Pushes the button to the far right
                fontSize: "1.2rem", // Larger close icon
                lineHeight: "1",
              }}
              aria-label="Close"
            >
              &times;
            </button>
          </Toast.Header>

          {/* Toast Body */}
          <Toast.Body style={{ color: "#000" }}>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      {/* Form */}
      <h2 className="mb-4">{editingDepartment ? "Edit Department" : "Create Department"}</h2>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="form-group">
          <label htmlFor="departmentName">
            Department Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            id="departmentName"
            className="form-control"
            placeholder="Enter department name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="departmentDescription">
            Description <span className="text-danger">*</span>
          </label>
          <textarea
            id="departmentDescription"
            className="form-control"
            placeholder="Enter department description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows="3"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={loading}
        >
          {loading ? "Processing..." : editingDepartment ? "Update" : "Create"}
        </button>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </form>

      {/* Departments Table */}
      <h2 className="mb-4">Departments</h2>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Department Name</th>
            <th scope="col">Description</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((department) => (
            <tr key={department._id}>
              <td>{department.name}</td>
              <td>{department.description}</td>
              <td>
                <button
                  onClick={() => handleEdit(department)}
                  className="btn btn-sm btn-primary me-2"
                >
                  <BsPencil />
                </button>
                <button
                  onClick={() => handleDelete(department._id)}
                  className="btn btn-sm btn-danger"
                >
                  <BsTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DepartmentPage;
