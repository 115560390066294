import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import uri from '../../config';
import SignupLogo from "./office workspace.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock, faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import spurTrack from "../../images/Spurtracklogo.png";
const Signup = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: localStorage.getItem("signupEmail") || "",
    password: "",
    organization: "",
    role: "SuperAdmin", // Default role
    invited: false, // Indicates whether the user is invited or not
  });
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const invitedEmail = localStorage.getItem("signupEmail");
    if (invitedEmail) {
      fetchInvitedUserData(invitedEmail);
    }
  }, []);

  const fetchInvitedUserData = async (invitedEmail) => {
    try {
      const response = await axios.post(`${uri}/api/invited-users`, { email: invitedEmail });
      const userData = response.data;

      setData((prevData) => ({
        ...prevData,
        email: userData.invitedEmail,
        role: userData.selectedRole,
        organization: userData.organization,
        invited: true, // Set invited flag to true
      }));
    } catch (error) {
      console.error("Error fetching invited user data:", error);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const url = `${uri}/api/users`;
      const { invited, ...postData } = data;
      const { data: res } = await axios.post(url, postData); // Send postData instead of data
      setMsg(res.message);
      setError("");
      if (res.status === 201) {
        clearLocalStorage();
        window.location.href = "/login";
      }
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
        setMsg("");
      }, 5000);
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("signupEmail");
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
      </style>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body d-flex">
            <div className="w-50 d-flex flex-column justify-content-center align-items-center p-4 mt-5">
              <img src={SignupLogo} alt="SpurQuality" className="img-fluid mb-4 mt-2" />
              <div className="mt-4">
                <h6>
                  <Link to="/login" className="text-decoration-none">
                    I am already a member
                  </Link>
                </h6>
              </div>
            </div>
            <div className="w-50 d-flex flex-column align-items-center p-4">
            <img src={spurTrack} alt="spurtrack Logo" className="img-fluid mb-1" style={{ width: '5vw' }} />
              <form className="w-100" onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faUser} size="lg" /></span>
                    </div>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      onChange={handleChange}
                      value={data.firstName}
                      required
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faUser} size="lg" /></span>
                    </div>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={handleChange}
                      value={data.lastName}
                      required
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faBuilding} size="lg" /></span>
                    </div>
                    <input
                      type="text"
                      placeholder="Organization"
                      name="organization"
                      onChange={handleChange}
                      value={data.organization}
                      required={!data.invited} // Organization is required for regular signup
                      readOnly={data.invited} // Organization is read-only for invited users
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} size="lg" /></span>
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={data.email}
                      readOnly // Email is read-only for both invited and non-invited users
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faLock} size="lg" /></span>
                    </div>
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      value={data.password}
                      required
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text"><FontAwesomeIcon icon={faUser} size="lg" /></span>
                    </div>
                    <input
                      type="text"
                      placeholder="Role"
                      name="role"
                      value={data.role}
                      readOnly // Role is read-only for both invited and non-invited users
                      className="form-control ms-2" // Add margin-start to create gap
                    />
                  </div>
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {msg && <div className="alert alert-success">{msg}</div>}
                <button type="submit" style={{
                        color: "#F0F3FA",
                        backgroundColor: "#26344b",
                        outlineColor: 'none',
                        border:'none'
                      }} className="btn btn-primary w-100" disabled={loading}>
                  {loading ? "Getting Started..." : "Get Started"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;