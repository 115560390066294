
import { React, useEffect, useState, useRef } from 'react';
import Header from "../Header/index";
import Sidebar from '../sidebar';
import './style.css';
import uri from '../../config';
import Model from 'react-modal';
import { RiExpandUpDownFill } from "react-icons/ri";
import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import { PiDotsThreeOutlineVerticalDuotone } from "react-icons/pi";
import Modal from 'react-bootstrap/Modal';
import { RxMagnifyingGlass } from "react-icons/rx";
import { TbCirclePlus } from "react-icons/tb";
import { useDownloadExcel } from 'react-export-table-to-excel';

const TeamPage = () => {
  const [data, setData] = useState([]);
  const [search, setsearch] = useState('');
  const [members, setMembers] = useState([]);
  const [toggle, setToggle] = useState(1);
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [sendInvitation, setSendInvitation] = useState(false);
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);


  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [activeStatus, setActiveStatus] = useState({});

  const [sortedBy, setSortedBy] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (column) => {
    if (sortedBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedBy(column);
      setSortDirection('asc');
    }

    const sortedMembers = [...data].sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[column] > b[column] ? -1 : 1;
      } else {
        return a[column] < b[column] ? -1 : 1;
      }
    });

    setData(sortedMembers);
  };

  const handleClickActive = () => {
    setIsActive(!isActive);
  };

  const handleClick = () => {
    setIsStrikethrough(!isStrikethrough);
  };

  const textStyle = {
    textDecoration: isStrikethrough ? 'line-through' : 'none',
    cursor: 'pointer',
  };


  useEffect(() => {
    const adminEmail = localStorage.getItem('email');
    if (adminEmail) setEmail(adminEmail);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${uri}/api/usermanagement`, {
          params: { adminEmail },
        });
        setData(response.data);
        // Populate active status in state based on fetched data
        const initialActiveStatus = {};
        response.data.forEach(user => {
          initialActiveStatus[user.id] = user.active;
        });
        setActiveStatus(initialActiveStatus);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchUser();
  }, []);

  const handleToggleStatus = async (id, newStatus) => {
    try {
      const response = await axios.put(`${uri}/api/updateStatus/${id}`, { active: newStatus });
      if (response.status === 200) {
        setActiveStatus((prevState) => ({
          ...prevState,
          [id]: newStatus,
        }));
      } else {
        alert('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleAddMember = async () => {
    const adminEmail = localStorage.getItem('email');
    const organization = localStorage.getItem('organization');

    try {
      const response = await axios.post(`${uri}/api/inviteuser`, {
        invitedEmail: email,
        selectedRole: 'member',
        adminEmail,
        organization,
      });

      if (response.data.success) {
        alert('Invitation sent successfully');
      } else if (response.data.error === 'User already invited') {
        alert('User already invited');
      }
    } catch (error) {
      alert(`Error inviting the user!: ${error.message}`);
    }
  };

  const [groupName, setGroupName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groups, setGroups] = useState([]);

  const handleAddGroup = async () => {
    const organization = localStorage.getItem('organization');
    try {
      const response = await axios.post(`${uri}/api/groups`, {
        organization,
        groupName,
        selectedUsers,
      });
      if (response.data.success) {
        alert('Group created successfully');
        // Optionally, reset group name and selected users
        setGroupName('');
        setSelectedUsers([]);
        handleClose1();

        const updatedGroups = await axios.get(`${uri}/api/groups`);
        setGroups(updatedGroups.data);
      } else {
        alert('Error creating group');
      }
    } catch (error) {
      alert(`Error in creating group: ${error.message}`);
    }
  };


  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateToggle = (index) => setToggle(index);

  const tableref = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: 'User-info',
    sheet: 'UserData',
    fileType: 'Xlsx'
  });

  const [selectAll, setSelectAll] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    setFilteredUsers(data);
  }, [data]);

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const checkedUsersObj = {};
      filteredUsers.forEach((data) => {
        checkedUsersObj[data.id] = true;
      });
      setCheckedUsers(checkedUsersObj);
    } else {
      setCheckedUsers({});
    }
  };
  const [selectAll1, setSelectAll1] = useState(false);
  const [checkedUsers1, setCheckedUsers1] = useState({});
  const [openDropdowns, setOpenDropdowns] = useState({});

  const handleSelectAllChange1 = (e) => {
    const checked = e.target.checked;
    setSelectAll1(checked);
    let newCheckedUsers1 = {};
    if (checked) {
      data.forEach((row) => {
        newCheckedUsers1[row.id] = true;
      });
    }
    setCheckedUsers1(newCheckedUsers1);
  };
  const toggleCheckbox = (data) => {
    const newCheckedUsers1 = { ...checkedUsers1, [data]: !checkedUsers1[data] };
    setCheckedUsers(newCheckedUsers1);
  };

  const toggleDropdown = (data) => {
    const newOpenDropdowns = { ...openDropdowns, [data]: !openDropdowns[data] };
    setOpenDropdowns(newOpenDropdowns);
  };

  useEffect(() => {
    // Close group dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (isOpen1 && !event.target.closest('.dropdown-menu-group')) {
        setIsOpen1(false);
      }
      Object.keys(openDropdowns).forEach((data) => {
        if (openDropdowns[data] && !event.target.closest('.dropdown-menu-options')) {
          toggleDropdown(data);
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen1, openDropdowns]);



  return (


    <div className="row2">
      <div className="col-md-2">
        <Header />
      </div>
      <div style={{ display: "flex", width: "auto", height: "100vh" }}>
        <Sidebar />

      <div className="team-container" style={{marginLeft:'5vw', marginTop:'15vw'}}>

        <h1>Team</h1>
        <div className="tabs">
          <div className="tabs" id="tab1" onClick={() => updateToggle(1)}><b>MEMBERS</b></div>
          <div className="tabs" id="tab2" onClick={() => updateToggle(2)}><b>GROUPS</b></div>
        </div>

        <div className={toggle === 1 ? "show-content" : "content"}>
          <div className="memberdiv">
            <div className="searchadd">
              <div className="search-bar">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-show" style={{ border: '2px solid  rgb(120, 114, 114)' }}> Show All </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Show active</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Show inactive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className='searchinput'>
                  <RxMagnifyingGlass class='magnifying-glass' />
                  <input type="text" placeholder="Search by name or email" onChange={(e) => setsearch(e.target.value)}
                    value={search} />
                </div>
              </div>
              <div className="memberadd">
                <button id="add-member" onClick={() => setVisible(true)}>ADD NEW MEMBER</button>

                <Model isOpen={visible} onRequestClose={() => setVisible(false)} style={{ overlay: { background: "rgba(0,0,0,0.9)" }, content: { width: "360px", height: "270px", borderRadius: '10px', display: 'flex', marginLeft: '35vw', marginTop: '20vh' } }}>
                  <form className='Model'>
                    <h5> Add Member</h5>
                    <h6>EMAIL</h6>
                    <input type="text" placeholder='Enter your email' style={{ margin: '2px', width: '100%' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                    <div className='invitation'>
                      <input type='checkbox' style={{ margin: '3px' }} checked={sendInvitation} onChange={() => setSendInvitation(!sendInvitation)} />
                      <p>Send an invitation</p>
                    </div>
                    <button style={{ margin: '3px' }} onClick={() => setVisible(false)}>Cancel</button>
                    <button style={{ margin: '3px' }} onClick={handleAddMember}>ADD</button>
                  </form>
                </Model>
              </div>
            </div>

            <div className="member-container">
              <div className='memdivheading'>
                <h6>Members</h6>
                <div className="export">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">Exports</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1" onClick={onDownload}>Save as CSV</Dropdown.Item>
                      <Dropdown.Item href="#/action-2" onClick={onDownload}>Save as Excel</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>


              <div className="table-header">
                <table ref={tableref}>
                  <thead>
                    <tr>
                      <th className="table-cell">
                        <input type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAll || checkedUsers[data.id]} style={{ marginright: '5px' }} />
                        NAME<RiExpandUpDownFill onClick={() => handleSort('name')} />
                      </th>
                      <th className="table-cell">EMAIL<RiExpandUpDownFill onClick={() => handleSort('email')} /></th>
                      <th className="table-cell">ROLE<RiExpandUpDownFill onClick={() => handleSort('role')} /></th>
                      <th className="table-cell">GROUP<RiExpandUpDownFill onClick={() => handleSort('group')} /></th>
                      <th className="table-cell">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.filter((row) => {
                      return search === '' ? row : (row.name.toLowerCase().includes(search.toLowerCase()));
                    }).map(row => (
                      <tr key={row.id}>
                        <td style={{ textDecoration: activeStatus[row.id] === false ? 'line-through' : 'none' }}>
                          <input type="checkbox" style={{ marginRight: '5px' }} /> {row.name}
                        </td>
                        <td style={{ textDecoration: activeStatus[row.id] === false ? 'line-through' : 'none' }}>{row.email}</td>
                        <td style={{ textDecoration: activeStatus[row.id] === false ? 'line-through' : 'none' }}>{row.role}</td>

                        <td><button className='group-button' onClick={toggleDropdown1}><TbCirclePlus />Group</button></td>



                        <div className="menuactive">
                          <button onClick={() => toggleDropdown(row.id)}><PiDotsThreeOutlineVerticalDuotone /></button></div>
                        {openDropdowns[row.id] && (
                          <div className='dropdown-menu-options'>
                            <button className='dropdown-menu-option1'>Edit</button>
                            {activeStatus[row.id] === false ? (
                                  <button
                                    className='dropdown-menu-option2 active'
                                    onClick={() => handleToggleStatus(row.id, true)}
                                  >
                                    Activate
                                  </button>
                                ) : (
                                  <button
                                    className='dropdown-menu-option2 inactive'
                                    onClick={() => handleToggleStatus(row.id, false)}
                                  >
                                    Deactivate
                                  </button>
                                )}



                            <Modal show={show} onHide={handleClose} className='Modal2'>
                              <Modal.Header closeButton>
                                Alert!
                              </Modal.Header>
                              <Modal.Body><b>Are You Sure ,You want to delete user?</b></Modal.Body>
                              <Modal.Footer>
                                <button variant="secondary" onClick={handleClose}>
                                  Cancel
                                </button>
                                <button variant="primary">
                                  YES
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        )}

                      </tr>
                    ))}
                  </tbody>
                </table>
                {isOpen1 && (<div className='dropdown-menu-group'>
                  <div className='dropdown-menu-group1'>
                    <RxMagnifyingGlass />
                    <input type="text" placeholder="Search Group" style={{ border: 'none' }} />
                  </div>
                  <h6>No groups yet</h6>
                  <p>Start typing to create one.</p>
                </div>)}
              </div>

            </div>
          </div>
        </div>

        <div className={toggle === 2 ? "show-content" : "content"}>
          <div className="groupdiv">
            <div className='addnewgroup'>
              <div id="addnewgroup">
                <input type="text" placeholder="Add New group+" />
              </div>
              <div>
                <button onClick={handleShow1}>ADD</button>

                <Modal show={show1} onHide={handleClose1} className='Modal3'>
                  <form>
                    <Modal.Header closeButton>
                      GROUP
                    </Modal.Header>
                    <Modal.Body>
                      <input
                        type="text"
                        placeholder="Enter group name"
                        onChange={(e) => setGroupName(e.target.value)}
                        value={groupName}
                      />
                      <br />
                      <br />
                      <Dropdown >
                        <Dropdown.Toggle id="selectuserdd"
                          onChange={(e) =>
                            setSelectedUsers(Array.from(e.target.selectedOptions, option => option.value))
                          }
                          value={selectedUsers}
                        >Select Users</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <tr id="user-table-row">
                            <td style={{ border: 'none' }}>
                              {filteredUsers.length > 0 ? (
                                filteredUsers.map((row) => (
                                  <tr key={row.id} style={{ fontSize: '14px' }}>
                                    <input
                                      type="checkbox"
                                      checked={selectAll || checkedUsers[row.id]}
                                      style={{ marginRight: '10px', marginLeft: '10px', fontFamily: 'Times New Roman', transform: 'scale(1.2)' }}
                                    />
                                    {row.name}
                                  </tr>
                                ))) : (<Dropdown.Item disabled>No User Found</Dropdown.Item>
                              )}
                            </td>
                          </tr>

                        </Dropdown.Menu>
                      </Dropdown>
                    </Modal.Body>
                    <Modal.Footer>
                      <button class='btngroupdivmodal' variant="secondary" onClick={handleClose1}>
                        Cancel
                      </button>
                      <button class='btngroupdivmodal' variant="primary" onClick={handleAddGroup}>
                        ADD
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>


              </div>
            </div>
            <div className='memdivheading'>
              <h6>Groups</h6>
              <div className="export">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">Exports</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Save as CSV</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Save as Excel</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="table-header1">
              <table>
                <thead>
                  <tr>
                    <th className="table-cell1">
                      <input type="checkbox" style={{ marginRight: '5px' }} />
                      GROUPNAME<RiExpandUpDownFill onClick={() => handleSort('groupName')} />
                    </th>
                    <th className="table-cell1">ORGANIZATION<RiExpandUpDownFill onClick={() => handleSort('organization')} /></th>
                    <th className="table-cell1">GROUP-MEMBERS<RiExpandUpDownFill onClick={() => handleSort('selectedUsers')} /></th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map((group) => (
                    <tr key={group._id}>
                      <td>{group.groupName}</td>
                      <td>{group.organization}</td>
                      <td>{group.selectedUsers.length}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
}

export default TeamPage;