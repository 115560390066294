import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import uri from '../../config';
import styles from "./styles.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import OTPpage from "./OTPpage.jpeg";
import spurTrack from "../../images/Spurtracklogo.png";

const Otp = () => {
  const [validUrl, setValidUrl] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const existingOtp = localStorage.getItem("otp");
    if (existingOtp) {
      submitOtp(existingOtp); // Simulate form submission with saved OTP
    }
  }, []);

  const handleChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.keyCode === 8 && index > 0 && !otpValues[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const submitOtp = async () => {
    try {
      const url = `${uri}/api/signup-otp-verify/verify/${otpValues.join("")}`;
      const { data } = await axios.get(url);
     
      setValidUrl(true);
      setError("");
      setMsg("");
      window.location = "/signup";
      
    } catch (error) {
      console.error(error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100" >
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
      </style>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body d-flex">
            <div className="w-70 d-flex flex-column justify-content-center align-items-center p-4">
              <img src={OTPpage} alt="ATAS" className="img-fluid mb-4" />
            </div>
            <div className="w-50 d-flex flex-column justify-content-center align-items-center p-4">
              <img src={spurTrack} alt="Company Logo" className="img-fluid mb-4" style={{ width: '10vw' }}/>
              <h3 className="mb-4">Enter your OTP!</h3>
              <form className="d-flex w-100 align-items-center justify-content-center mb-4">
                {otpValues.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    value={value}
                    maxLength={1}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    ref={(input) => (inputRefs.current[index] = input)}
                    className="form-control text-center mx-1"
                    style={{ width: "40px", height: "40px", fontSize: "24px" }}
                  />
                ))}
              </form>
              {error && <div className="alert alert-danger">{error}</div>}
              {msg && <div className="alert alert-success">{msg}</div>}
              <button className="btn btn-primary mb-3" onClick={() => submitOtp(otpValues.join(""))}>
                Verify OTP
              </button>
              <Link to="/signup-email-verify" className="text-decoration-none">
                <p className="mb-0">Resend OTP</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
