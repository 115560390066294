import React, { useState, useRef, useEffect } from "react";
import user from "./UsersIcon.png";
import './navbar.css';
import uri from '../../config';
import axios from 'axios';
import Modal from 'react-modal';
import { CiBellOn } from "react-icons/ci";
import { IoIosLogOut, IoIosSettings } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import Avatar from 'react-avatar';
import { Button } from "react-bootstrap";
import logo from "./SpurTrackerlogo.png";
import { CiCircleQuestion } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';
import { FaTrash, FaCheck } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const Navigation = () => {
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(localStorage.getItem('userName') || 'Profile');
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [bell, setBell] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) setEmail(storedEmail);

    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(`${uri}/api/profile/getimage`, {
          params: { email: storedEmail }
        });
        if (response.data.profileImage) {
          setImage(response.data.profileImage);
        } else {
          setImage(null);
        }
      } catch (error) {
        console.error('Error fetching profile image', error);
      }
    };

    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${uri}/api/notifications`);
        setNotifications(response.data.notifications);
      } catch (error) {
        console.error('Error fetching notifications', error);
      }
    };

    fetchProfileImage();
    fetchNotifications();
  }, []);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    if (!file.type.match('image/jpeg') && !file.type.match('image/png')) {
      alert('Only JPG, JPEG, or PNG images are allowed.');
      return;
    }

    if (file.size > 1024 * 1024) {
      alert('File size exceeds the limit of 1 MB.');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);
    formData.append('email', email);

    try {
      await axios.post(`${uri}/api/profile/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    } catch (error) {
      console.error('Error uploading image', error);
    }
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(newName);
    localStorage.setItem('userName', newName);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    localStorage.setItem('email', email);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleBell = () => {
    setBell(!bell);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/home";
  };

  const goHome = () => {
    window.location.href = "/";
  };

  const markAllAsRead = async () => {
    try {
      await axios.put(`${uri}/api/notifications/mark-all-as-read`);
      fetchNotifications();
    } catch (error) {
      console.error('Error marking all as read', error);
    }
  };

  const deleteAllNotifications = async () => {
    try {
      await axios.delete(`${uri}/api/notifications/delete-all`);
      setNotifications([]);
    } catch (error) {
      console.error('Error deleting all notifications', error);
    }
  };

  const deleteReadNotifications = async () => {
    try {
      await axios.delete(`${uri}/api/notifications/delete-read`);
      fetchNotifications();
    } catch (error) {
      console.error('Error deleting read notifications', error);
    }
  };

  const markAsRead = async (notificationId) => {
    try {
      await axios.put(`${uri}/api/notifications/mark-as-read/${notificationId}`);
      fetchNotifications();
    } catch (error) {
      console.error('Error marking as read', error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${uri}/api/notifications`);
      setNotifications(response.data.notifications);
    } catch (error) {
      console.error('Error fetching notifications', error);
    }
  };

  const notifyNew = (message) => {
    toast.info(message);
  };

  return (
    <header>
      <div className="navbar" style={{ width: '98.9vw' }}>
        <div className="side1">
          <img onClick={goHome} className="logo" id="image" src={logo} alt="Logo" />
        </div>
        <div className="side2">
          <div className="navbar-bell">
            <CiBellOn size={24} onClick={toggleBell} />
          </div>

          <div className="navbar-user">
            <button onClick={toggleModal} className="btn-Model">
              {image ? (
                <img src={image} alt="Profile" style={{ borderRadius: '50%', height: '40px', width: '40px' }} />
              ) : (
                <Avatar name={name} size={30} round={true} />
              )}
            </button>
          </div>

          <Modal isOpen={modal} onRequestClose={() => setModal(false)} style={{ overlay: { background: "none" }, content: { width: "340px", height: "375px", marginLeft: "auto", marginTop: "10vh" } }}>
            {modal && (
              <div onClick={toggleModal} className="profile-c">
                <div className="profile-image">
                  {image ? (
                    <img src={image} alt="Profile" width="70" height="70" style={{ borderRadius: '50%' }} />
                  ) : (
                    <Avatar name={name} size={70} round={true} />
                  )}
                </div>
                <div className="pf">
                  <div className="profile-name">
                    <input
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      disabled={true}
                    /><br />
                    <input
                      type="text"
                      value={email}
                      onChange={handleEmailChange}
                      disabled={true}
                    /><hr />
                  </div>
                  <div className="btn">
                    <div>
                      <div className="button1"><CgProfile className="profile-icon" /> <a href="profile"><button>My Profile</button></a></div>
                      <div className="button2"><IoIosSettings className="preference-icon" /><button> Preferences</button></div>
                      <hr />
                      <div className="button3"><IoIosLogOut className="logout-icon" /><button onClick={handleLogout}> Logout</button></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>

      {bell && (
        <div className="notification-dropdown">
          <div className="notification-header">
            <div className="notification-button" onClick={markAllAsRead}> <FaCheck /></div>
            <div className="notification-button" onClick={deleteReadNotifications}><FaTrash /> </div>
            <div className="notification-button" onClick={deleteAllNotifications}>Delete All</div>
          </div>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div key={index} className={`notification-item ${notification.read ? 'read' : 'unread'}`}>
                <p>{notification.message}</p>
                {!notification.read && <div className="notification-button" onClick={() => markAsRead(notification.id)}>Mark as Read</div>}
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </div>
      )}
      <ToastContainer />
    </header>
  );
};

export default Navigation;
