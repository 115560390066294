import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Header/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCalendarAlt } from "react-icons/fa";
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import styles from "./styles.module.css";
import Sidebar from "../sidebar";
import uri from '../../config';

const Organization = () => {
    const [tasks, setTasks] = useState([{
        objective: '',
        keyResult: '',
        inputTask: '',
        output: '',
        details: '',
        efficiencyMetrics: '',
        effectivenessMetrics: '',
        startDate: new Date(),
        startTime: '',
        endDate: new Date(),
        endTime: '',
        userName: ''
    }]);
    const [showModal, setShowModal] = useState(false);
    const [fetchedTasks, setFetchedTasks] = useState([]);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        const userName = localStorage.getItem("userName");
        const organization = localStorage.getItem("organization");
        if (userName) {
            setTasks(tasks.map(task => ({ ...task, userName })));
        }
        fetchTasks(userName, organization);
    }, []);

    useEffect(() => {
        if (!showModal) {
            setTasks([{
                objective: '',
                keyResult: '',
                inputTask: '',
                output: '',
                details: '',
                efficiencyMetrics: '',
                effectivenessMetrics: '',
                startDate: new Date(),
                startTime: '',
                endDate: new Date(),
                endTime: '',
                userName: localStorage.getItem("userName") || ''
            }]);
        }
    }, [showModal]);

    const fetchTasks = async (userName, organization) => {
        try {
            const response = await axios.get(`${uri}/api/task`, { params: { organization } });
            setFetchedTasks(response.data);
        } catch (error) {
            console.error('There was an error fetching the tasks!', error);
        }
    };

    const handleEdit = (taskId) => {
        // Implement the edit functionality here
        
    };

    const handleDelete = async (taskId) => {
        try {
            await axios.delete(`${uri}/api/deletetask/${taskId}`);
            setToast({ show: true, message: 'Task deleted successfully!', type: 'success' });
            fetchTasks(localStorage.getItem("userName"), localStorage.getItem("organization")); // Refresh the task list
        } catch (error) {
            setToast({ show: true, message: 'There was an error deleting the task!', type: 'danger' });
        }
    };

    const handleTaskChange = (index, event) => {
        const newTasks = tasks.slice();
        newTasks[index][event.target.name] = event.target.value;
        setTasks(newTasks);
    };

    const handleDateChange = (index, date, name) => {
        const newTasks = tasks.slice();
        newTasks[index][name] = date;
        setTasks(newTasks);
    };

    const addTask = () => {
        const userName = localStorage.getItem("userName") || '';
        setTasks([...tasks, {
            objective: '',
            keyResult: '',
            inputTask: '',
            output: '',
            details: '',
            efficiencyMetrics: '',
            effectivenessMetrics: '',
            startDate: new Date(),
            startTime: '',
            endDate: new Date(),
            endTime: '',
            userName
        }]);
    };

    const removeTask = (index) => {
        const newTasks = tasks.slice();
        newTasks.splice(index, 1);
        setTasks(newTasks);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const organization = localStorage.getItem("organization");
        const formattedTasks = tasks.map(task => {
            const startDateTime = new Date(task.startDate);
            const endDateTime = new Date(task.endDate);
            const [startHours, startMinutes] = task.startTime.split(':').map(Number);
            const [endHours, endMinutes] = task.endTime.split(':').map(Number);
            startDateTime.setHours(startHours, startMinutes);
            endDateTime.setHours(endHours, endMinutes);
            return {
                ...task,
                startDateTime,
                endDateTime
            };
        });
        try {
            await axios.post(`${uri}/api/task`, {
                organization,
                tasks: formattedTasks
            });
            setShowModal(false); // Close the modal on successful submission
            fetchTasks(localStorage.getItem("userName"), organization); // Refresh the task list
            setToast({ show: true, message: 'Task created successfully!', type: 'success' });
            // Reset task fields
            setTasks([{
                objective: '',
                keyResult: '',
                inputTask: '',
                output: '',
                details: '',
                efficiencyMetrics: '',
                effectivenessMetrics: '',
                startDate: new Date(),
                startTime: '',
                endDate: new Date(),
                endTime: '',
                userName: localStorage.getItem("userName") || ''
            }]);
        } catch (error) {
            setToast({ show: true, message: 'There was an error creating the task!', type: 'danger' });
        }
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button className="btn btn-outline-secondary d-flex align-items-center" onClick={onClick} ref={ref}>
            <FaCalendarAlt className="mr-2" />
            {value || 'Select Date'}
        </button>
    ));

    useEffect(() => {
        if (toast.show) {
            const timer = setTimeout(() => {
                setToast({ show: false, message: '', type: '' });
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [toast]);

    return (
        <div className="row2">
            <div className="col-md-2">
                <Header />
            </div>
            <div style={{ display: "flex", width: "auto", height: "100vh" }}>
                <Sidebar />
                <div className="container-fluid mt-5" style={{ marginLeft: '17vw', marginTop:"7vw" }}>

                    <div className="col-md-9">
                        <h1>Organization Setup</h1>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setShowModal(true)}
                        >
                            Create Task
                        </button>

                        <table className={`${styles.table} table table-striped mt-4`}>
                            <thead>
                                <tr>
                                    <th>Task Name</th>
                                    <th>Created Date</th>
                                    <th>Objective</th>
                                    <th>Details</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fetchedTasks.map(task => (
                                    <tr key={task._id}>
                                        <td>{task.taskName}</td>
                                        <td>{new Date(task.createdAt).toLocaleDateString()}</td>
                                        <td>{task.objective}</td>
                                        <td>{task.details}</td>
                                        <td>
                                            <button className={`btn btn-primary btn-sm m-1 ${styles.btn}`} onClick={() => handleEdit(task._id)}>
                                                <BsPencilSquare className={`${styles['edit-icon']}`} />
                                                <span className={styles['edit-label']}></span>
                                            </button>
                                            <button className={`btn btn-danger btn-sm m-1 ${styles.btn}`} onClick={() => handleDelete(task._id)}>
                                                <BsTrash className={`${styles['delete-icon']}`} />
                                                <span className={styles['delete-label']}></span>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Modal for creating tasks */}
                        {showModal && (
                            <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Create a Task</h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                aria-label="Close"
                                                onClick={() => setShowModal(false)}
                                            />
                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={handleSubmit}>
                                                {tasks.map((task, index) => (
                                                    <div key={index} className="card my-3">
                                                        <div className="card-body">
                                                            <h5 className="card-title">Task {index + 1}</h5>
                                                            <div className="form-group">
                                                                <label>User Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="userName"
                                                                    value={task.userName}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Task Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="taskName"
                                                                    value={task.taskName}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Objective</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="objective"
                                                                    value={task.objective}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Key Result</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="keyResult"
                                                                    value={task.keyResult}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Input Task</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="inputTask"
                                                                    value={task.inputTask}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Output</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="output"
                                                                    value={task.output}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Details</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="details"
                                                                    value={task.details}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Efficiency Metrics</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="efficiencyMetrics"
                                                                    value={task.efficiencyMetrics}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Effectiveness Metrics</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="effectivenessMetrics"
                                                                    value={task.effectivenessMetrics}
                                                                    onChange={(e) => handleTaskChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                            {tasks.length > 1 && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => removeTask(index)}
                                                                >
                                                                    Remove Task
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary mb-3"
                                                    onClick={addTask}
                                                >
                                                    Add Task
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary mb-3"
                                                >
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Toast Notification */}
                        <div
                            className={`toast align-items-center text-white ${toast.type === 'success' ? 'bg-success' : 'bg-danger'} border-0 position-fixed top-0 start-50 translate-middle-x mt-3`}
                            style={{ zIndex: 1051, display: toast.show ? 'block' : 'none' }}
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex">
                                <div className="toast-body">
                                    {toast.message}
                                </div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                    aria-label="Close"
                                    onClick={() => setToast({ show: false, message: '', type: '' })}
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Organization;