import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./components/Main";
import Signup from "./components/Singup";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import PasswordReset from "./components/PasswordReset";
import Getstartedverification from "./components/GetStartedVerification";
import HomePage from "./components/Home";
import Otp from "./components/OtpPage";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Blogs from "./components/Blogs";
import Navigation from "./components/navbar2";
import Sidebar from "./components/sidebar";
// import profileDropdown from "./components/profileDropdown";
// import Profile2 from "./components/profilePage";
import CalendarComponent from "./components/Calendar";
import Organization from "./components/Organization";
import NotFound from './components/notfound';
import Profile from "./components/profile";
import TeamPage from "./components/teampage";
import TaskTable from "./components/Timesheet";
import AdminDashboard from "./components/AdminDashboard"
import Projects from "./components/projectPage";
import ClientManager from "./components/ClientPage";
import ProjectDetails from "./components/projectdetails";
import Orgsetup from "./components/OrgSetup/Index";
import Orgdashboard from "./components/OrganizationDashboard";
import ReportsPage from "./components/Reports/Index";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Team from "./components/Team";
import Milestone from "./components/Milestone";
import WeeklyStatusReport from "./components/WSR";
import ApproverWSR from "./components/approverwsr";
import MySpacePage from "./components/Myspace";
function App() {
	const user = localStorage.getItem("token");
	return (
		<Routes>
			{user && <Route path="/" exact element={<CalendarComponent />} />}
			<Route path="/signup" exact element={<Signup />} />
			<Route path="/main" exact element={<Main />} />
			<Route path="https://spurqlabs.com/about/" exact element={<AboutUs />} />
			<Route path="/contact" exact element={<ContactUs />} />
			<Route path="https://spurqlabs.com/blogs/" exact element={<Blogs />} />
			<Route path="/login" exact element={<Login />} />
			<Route path="/signup-otp-verify/verify/:otp" exact element={<Otp />} />
			<Route path="/signup-otp-verify" exact element={<Otp />} />
			<Route path="/home" exact element={<HomePage />} />
			<Route path="/signup-email-verify" exact element={<Getstartedverification />} />
			<Route path="/" element={<Navigate replace to="/home" />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/password-reset/:id/:token" element={<PasswordReset />} />
			<Route path="/navbar" element={<Navigation />} />
			<Route path="/sidebar" element={<Sidebar />} />
			<Route path="/calendar" element={<CalendarComponent />} />
			<Route path="/organization" element={<Organization />} />
			<Route path="*" element={<NotFound />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/timesheet" element={<TaskTable/>} />
			<Route path="/project" element={<Projects />} />
			<Route path="/clients" element={<ClientManager />} />
			<Route path="/profileDropdown" element={<profileDropdown/>}/>
			<Route path="/projectdetails" element={<ProjectDetails/>}/>
			<Route path="/admindashboard" element={<AdminDashboard/>}/>
			<Route path="/orgsetup" element ={<Orgsetup/>}/>
			<Route path="/orgdashboard" element ={<Orgdashboard/>}/>
			<Route path="/orgreports" element ={<ReportsPage/>}/>		
			<Route path="/team" element ={<Team/>}/>
			<Route path="/milestone" element ={<Milestone/>}/>
			<Route path="/wsr" element ={<WeeklyStatusReport/>}/>
			<Route path="/approverwsr" element ={<ApproverWSR/>}/>
			<Route path="/myspace" element ={<MySpacePage/>}/>
		</Routes>
	);
}

export default App;
