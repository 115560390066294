import React, { useState, useEffect, useRef } from 'react';
import { Navbar, Nav, Dropdown, Badge } from 'react-bootstrap';
import { BellFill } from 'react-bootstrap-icons';
import { RxCross2 } from "react-icons/rx";
import './header.css';
import uri from '../../config';
import axios from 'axios'; // Assuming you use axios for HTTP requests

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNotifications(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Function to fetch notifications
    const fetchNotifications = async () => {
      try {
        const userName = localStorage.getItem('userName'); // Get userName from localStorage
        if (!userName) {
          console.error('No userName found in localStorage');
          return;
        }

        const response = await axios.get(`${uri}/api/notifications?userName=${userName}`); // Send userName as a query parameter
        setNotifications(response.data); // Update state with notifications
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    // Initial fetch
    fetchNotifications();

    // Set an interval to fetch notifications every 3 seconds (3000ms)
    const intervalId = setInterval(fetchNotifications, 3000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Function to clear all notifications


  // Function to toggle unread notifications
  const toggleShowUnread = () => {
    setShowUnread((prev) => !prev);
  };

  // Filter notifications based on the unread status
  const filteredNotifications = showUnread
    ? notifications.filter((notification) => !notification.read)
    : notifications;

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleLogout = () => {
    localStorage.clear(); // This clears all localStorage data
    window.location.href = '/home'; // Redirect to home page
  };

  const userName = localStorage.getItem('userName');
  // Function to mark a notification as read
  const markNotificationAsRead = async (notificationId) => {
    try {
      const userName = localStorage.getItem('userName');
      if (!userName) {
        console.error('No userName found in localStorage');
        return;
      }

      await axios.put(`${uri}/api/notifications/mark-as-read`, {
        notificationId, // Send the notification ID
        userName, // Pass the userName to identify if the user is the approver or userName
      });

      // Update the notifications state by marking the notification as read
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === notificationId ? { ...notification, read: true } : notification
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  // Function to delete a single notification
  const deleteNotification = async (notificationId) => {
    try {
      await axios.delete(`${uri}/api/notifications/${notificationId}`); // Delete the notification by its ID

      // Remove the notification from the state
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification._id !== notificationId)
      );
    } catch (error) {
      console.error('Failed to delete notification:', error);
    }
  };

  const clearAllNotifications = async () => {
    try {
      const userName = localStorage.getItem('userName'); // Get the userName from localStorage
      if (!userName) {
        console.error('No userName found in localStorage');
        return;
      }

      await axios.delete(`${uri}/api/notifications/clear-all?userName=${userName}`); // Make a DELETE request to clear notifications
      setNotifications([]); // Clear the notifications state in the frontend
    } catch (error) {
      console.error('Failed to clear notifications:', error);
    }
  };

  const markAllAsRead = async () => {
    try {
      const userName = localStorage.getItem('userName'); // Get the userName from localStorage
      if (!userName) {
        console.error('No userName found in localStorage');
        return;
      }

      await axios.put(`${uri}/api/notifications/mark-all-read?userName=${userName}`); // Make a PUT request to mark notifications as read
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({
          ...notification,
          read: true,
        }))
      ); // Update the state to mark all notifications as read in the frontend
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };
  return (
    <Navbar expand="lg" className="header fixed-top">
      <Navbar.Brand href="/" className="app-name">
        SpurTrack
      </Navbar.Brand>

      <Nav className="ml-auto d-flex align-items-center">
        {/* Notification Bell */}
        <Nav.Link href="#" className="notification-bell" onClick={toggleNotifications}>
          <BellFill size={18} />
          <Badge pill variant="danger">
            {notifications.filter((notification) => !notification.read).length > 10
              ? "10+"
              : notifications.filter((notification) => !notification.read).length}
          </Badge>
        </Nav.Link>

        {/* Notification Dropdown */}
        {showNotifications && (
          <div
            className="notification-dropdown"
            style={{ marginRight: "8vw", width: "38vw" }}
            ref={dropdownRef} // Reference for outside click detection
          >
            <h6 style={{ display: "flex", alignItems: "center" }}>
              Notifications
              <div className="form-check form-switch" style={{ marginLeft: "22vw" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={showUnread}
                  onChange={toggleShowUnread}
                />
                <label className="form-check-label">Unread</label>
              </div>
            </h6>

            {filteredNotifications.length > 0 ? (
              filteredNotifications.map((notification) => (
                <div
                  key={notification._id}
                  className={`notification-item ${notification.read ? "" : "unread"}`}
                  onClick={() => markNotificationAsRead(notification._id)}
                >
                  <div className="notification-content">
                    <span>{notification.message}</span> <br />
                  </div>

                  <RxCross2
                    className="delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteNotification(notification._id);
                    }}
                  />
                  <hr />
                </div>
              ))
            ) : (
              <div>No notifications found.</div>
            )}

            <div className="notification-footer">
              <button className="mark-all-read" onClick={markAllAsRead}>
                Mark all as read
              </button>
              <button className="clear-all" onClick={clearAllNotifications}>
                Clear All
              </button>
            </div>
          </div>
        )}
        {/* User Name Dropdown */}
        <Dropdown show={showDropdown} onToggle={toggleDropdown} align="end">
          <Dropdown.Toggle
            variant="none"
            className="user-name"
            id="dropdown-avatar"
            onClick={toggleDropdown}
            as="span"
          >
            {userName}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
};

export default Header;